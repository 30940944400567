import { FilterButton } from 'ogp/components/views/administration/shared';
import { Header, LwButton, Page, Toolbox } from 'redesign';
import { useToggle } from 'shared/hooks';
import { useCallback, useState } from 'react';
import { HQPlacementsDataProvider } from './placements-table';
import { usePlacementsPage } from './utils/hooks/usePlacementPage';
import { BulkCancelSidebar } from './bulk-placements-cancel/bulk-cancel-sidebar';

export const HQPlacements = () => {
  const [selectedPlacementIds, setSelectedPlacementIds] = useState<string[]>([]);
  const [isBulkCancelSideBarOpen, setIsBulkCancelSideBarOpen] = useState(false);

  const { filters: appliedFilters, setFilters, selectedFilterCount } = usePlacementsPage();
  const { on: filtersOpen, toggle: toggleFilters } = useToggle();

  const handleSelectionChange = useCallback((ids: string[]) => {
    setSelectedPlacementIds(ids);
  }, []);

  return (
    <Page
      header={<Header titleText="Plaatsingen" />}
      toolbox={
        <Toolbox>
          <LwButton
            disabled={selectedPlacementIds.length === 0}
            color="secondary"
            onClick={() => setIsBulkCancelSideBarOpen(true)}
            iconColorMode="fill"
          >
            Annuleren{selectedPlacementIds.length > 0 ? ` (${selectedPlacementIds.length})` : ''}
          </LwButton>
          <Toolbox.PushRight>
            <FilterButton onClick={toggleFilters} nrOfActiveFilters={selectedFilterCount} />
          </Toolbox.PushRight>
        </Toolbox>
      }
    >
      <HQPlacementsDataProvider
        setFilters={setFilters}
        appliedFilters={appliedFilters}
        filtersOpen={filtersOpen}
        toggleFilters={toggleFilters}
        selectedPlacementIds={selectedPlacementIds}
        onSelectionChange={handleSelectionChange}
      />

      {isBulkCancelSideBarOpen && (
        <BulkCancelSidebar
          selectedPlacementIds={selectedPlacementIds}
          isBulkSideBarOpen={isBulkCancelSideBarOpen}
          setIsSidebarOpen={setIsBulkCancelSideBarOpen}
        />
      )}
    </Page>
  );
};
