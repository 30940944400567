import { IconCalendarView, IconGridView } from 'assets/img';
import { LwButton } from 'redesign';

type PlanningViewSwitcherProps = {
  type: 'table' | 'calendar';
  switchView: () => void;
};

export const PlanningViewSwitcher = ({ type, switchView }: PlanningViewSwitcherProps) => {
  return (
    <LwButton data-testid={'switch-view-icon'} onClick={switchView} color="secondary">
      {switcher[type]}
    </LwButton>
  );
};

const switcher = {
  table: <IconGridView style={{ flexShrink: 0 }} />,
  calendar: <IconCalendarView style={{ flexShrink: 0 }} />,
};
