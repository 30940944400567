import { partnerWorkerService } from 'api/services';
import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { useInfiniteQuery } from 'react-query';
import { useSiteDetection } from 'shared/hooks';

export const usePartnerGetWorkers = (
  shiftId: string,
  pageSize: number,
  fullNameSearch: string | undefined
) => {
  const { site } = useSiteDetection();
  return useInfiniteQuery({
    queryKey: QUERY_KEYS_PARTNER.shiftPlanningShiftWorkers(shiftId, fullNameSearch),
    queryFn: async ({ pageParam = 0 }) => {
      return await partnerWorkerService.getShiftWorkers(
        site,
        shiftId,
        fullNameSearch,
        pageParam,
        pageSize
      );
    },
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};
