import React, { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import { PlanningViewType } from 'ogp/components/views/planning/planning/planning.types';
import { useLocation } from 'react-router';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { useCompanyRelativePath } from './use-company-relative-path';
import { routes } from '../Routes';

type UpdatePlanningPathArguments = {
  date?: DateTime | null;
  shiftDetailId?: string | null;
  view?: PlanningViewType | null;
};

const DAY_COUNT = 7;
const { queryParams } = routes.Shifts;

/**
 * Helper to create paths for the planning screen.
 *
 * Undefined values leave the current query param in the URL intact.
 * Null values remove them.
 * Other values are set.
 */
export function useUpdatePlanningPath() {
  const location = useLocation();
  const generatePath = useCompanyRelativePath();

  return useCallback(
    ({ date, shiftDetailId, view }: UpdatePlanningPathArguments) => {
      const path = generatePath(routes.Shifts);
      const params = new URLSearchParams(location.search); // Using location.search to keep other params intact.

      if (date === null) {
        params.delete(queryParams.PLANNING_DATE);
      } else if (date) {
        params.set(queryParams.PLANNING_DATE, date.toISODate());
      }

      if (shiftDetailId === null) {
        params.delete(queryParams.SHIFT_DETAIL_ID);
      } else if (shiftDetailId) {
        params.set(queryParams.SHIFT_DETAIL_ID, shiftDetailId);
      }

      params.sort();

      return `${path}?${params}`;
    },
    [generatePath, location.search]
  );
}

type ShiftDetailParams = {
  shiftDetailId: string | null;
  date: DateTime | null;
};

/**
 * Helper to read the query params for the planning screen.
 */
export function useReadPlanningPathParams(): ShiftDetailParams {
  const queryString = useQueryParams();

  const shiftDetailId = queryString.get(queryParams.SHIFT_DETAIL_ID);
  const dateStr = queryString.get(queryParams.PLANNING_DATE);

  const date = useMemo(() => (dateStr !== null ? DateTime.fromISO(dateStr) : dateStr), [dateStr]);

  return { shiftDetailId, date };
}

const usePlanningDateRange = () => {
  const { date: planningDate } = useReadPlanningPathParams();

  const startDate = React.useMemo(
    () =>
      planningDate && planningDate.startOf('week').isValid
        ? planningDate.startOf('week')
        : DateTime.now(),
    [planningDate]
  );
  const endDate = React.useMemo(() => startDate.plus({ days: DAY_COUNT - 1 }), [startDate]);

  return { startDate, endDate };
};

export { usePlanningDateRange };
