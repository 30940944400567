import { Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { BulkShiftArchiveForm } from './bulk-shift-archive-form';
import { FormCheckbox, StyledErrorText } from '../../../../../../../redesign';

export const BulkShiftArchiveFormInputs = () => {
  const { formState, control, watch, setValue } = useFormContext<BulkShiftArchiveForm>();
  const isArchiveShiftsWithPlacementsEnabled = watch('archiveShiftsWithPlacements');
  const error = formState.errors.root;
  return (
    <Grid container padding={4} spacing={4}>
      <Grid padding={4} item xs={12}>
        <Typography variant="h2">Shifts verwijderen</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormCheckbox
          name="archiveShiftsWithPlacements"
          control={control}
          label="Annuleer actieve plaatsingen"
          onChange={(val) => (!val ? setValue('archiveShiftsWithPayout', false) : undefined)}
        />
      </Grid>
      <Grid item xs={12}>
        <FormCheckbox
          name="archiveShiftsWithPayout"
          control={control}
          label="Annuleer met 50%-regel"
          disabled={!isArchiveShiftsWithPlacementsEnabled}
        />
      </Grid>
      {error && (
        <Grid item xs={12}>
          <StyledErrorText className="error-state">{error.message}</StyledErrorText>
        </Grid>
      )}
    </Grid>
  );
};
