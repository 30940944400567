import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToolboxFormCreate } from 'shared/components/common/toolbox-form-create';
import { ProjectForm } from 'shared/components/project-forms/components/project-form';
import { Header, Page } from 'redesign';
import { useAlert } from 'shared/components/alerts';
import { CompanyEmploymentTypes, InputSelectOption } from '@types';
import { HQProjectFormTypeCreate } from 'shared/components/project-forms/types/project-form.types';
import { useGetJobDetails } from 'shared/components/work-forms/details/hooks/use-get-job-details';
import { RenderUnsettledUI } from 'shared/components';
import { useGetDepartmentAsDetail } from 'shared/components/shift-forms/components/forms/shift-form/hooks/use-get-department-as-detail';
import { useCompanyRelativePath } from '../../../../../../../hooks';
import { paths } from '../../../../../../../paths';
import { routes } from '../../../../../../../Routes';
import { useCreateProject } from '../../../hooks/use-create-project';
import { OgpProjectCreatePayload } from '../../../../../../../services/project-service.types';

type ProjectCreateProps = {
  successMessage: string;
  pageTitle: string;
  departments: InputSelectOption[];
  companyEmploymentType: CompanyEmploymentTypes;
};

export const ProjectCreateFormPage = ({
  successMessage,
  pageTitle,
  departments,
  companyEmploymentType,
}: ProjectCreateProps) => {
  const { alertSuccess, alertError } = useAlert();
  const formMethods = useForm<HQProjectFormTypeCreate>();

  const jobId = formMethods.watch('jobId');
  const departmentId = formMethods.watch('departmentId');
  const departmentDetailQuery = useGetDepartmentAsDetail(departmentId, departmentId !== undefined);

  const jobDetailsQuery = useGetJobDetails(jobId);

  const navigate = useNavigate();
  const generatePath = useCompanyRelativePath();
  const mutation = useCreateProject();

  const handleSubmit = () => {
    formMethods.handleSubmit((data: HQProjectFormTypeCreate) => {
      const payload: OgpProjectCreatePayload = {
        jobId: data.jobId,
        startDate: data.startDate,
        endDate: data.endDate,
        weekHourVolume: +data.weeklyHours,
        freelanceProperties: data.isFreelance
          ? {
              hourlyRateCents: Math.round(+data.hourlyRate * 100),
            }
          : undefined,
        isTempWork: data.isTempwork,
      };
      mutation.mutate(payload, {
        onSuccess: ({ id }) => {
          alertSuccess(successMessage);
          navigate(generatePath(routes.Projects, {}, { id }));
        },
        onError: (e) => alertError(e),
      });
    })();
  };

  if (departmentDetailQuery.status !== 'success' && departmentDetailQuery.status !== 'idle') {
    return <RenderUnsettledUI data={departmentDetailQuery} />;
  }

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={pageTitle} />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={() => navigate(generatePath(paths.Projects), { replace: true })}
          />
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <ProjectForm
              mode="create"
              jobsData={departmentDetailQuery?.data?.jobs ?? []}
              isProjectWithActivePlacement={false}
              departments={departments}
              workDetails={jobDetailsQuery.data}
              companyEmploymentType={companyEmploymentType}
            />
          </Grid>
        </Grid>
      </Page>
    </FormProvider>
  );
};
