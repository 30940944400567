import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { BulkShiftActionDataResponse } from 'hq/services/shift-service.types';
import { shiftService } from '../../../../../services';
import { ShiftBulkEditPayload } from '../../../../../services/shift-planning-service.types';
import { QUERY_KEYS_HQ } from '../../../../../query-keys';

type MutationOptions = UseMutationOptions<
  BulkShiftActionDataResponse,
  unknown,
  ShiftBulkEditPayload
>;

export const useBulkUpdateShift = () => {
  const mutationFn = async (data: ShiftBulkEditPayload) =>
    await shiftService.bulkUpdateShifts(data);
  const queryClient = useQueryClient();
  const options: MutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.shifts());
      queryClient.invalidateQueries(QUERY_KEYS_HQ.shiftPlanningShifts());
    },
  };

  return useMutation(mutationFn, options);
};
