import { Box, Divider, styled } from '@mui/material';
import { WorkFormDetails } from 'shared/components/work-forms/details/work-form-details';
import { DetailsForWorkTemplate } from 'shared/components/work-forms/details/work-form-details.types';
import { CompanyEmploymentTypes, InputSelectOption } from '@types';
import { FormMode } from 'shared/components/shift-forms/components/forms/shared/types';
import { ProjectFormInputs } from './project-form-inputs';

export type ProjectFormProps = {
  jobsData?: { id: string; name: string; flexPoolOnly: boolean }[];
  departments: InputSelectOption[];
  mode: FormMode;
  isProjectWithActivePlacement: boolean;
  workDetails: DetailsForWorkTemplate | undefined;
  companyEmploymentType: CompanyEmploymentTypes | undefined;
};

export const ProjectForm = (options: ProjectFormProps) => {
  return (
    <Box display="flex" flexDirection="row">
      <Box display="flex" flexDirection="row" height="100%" flex="1">
        <ProjectFormInputs
          departments={options.departments}
          mode={options.mode}
          isProjectWithActivePlacement={options.isProjectWithActivePlacement}
          jobsData={options.jobsData}
          companyEmploymentType={options.companyEmploymentType}
        />
      </Box>
      <Box>
        <StyledDivider />
      </Box>
      <Box flex="1" display="flex">
        <WorkFormDetails workDetails={options.workDetails} />
      </Box>
    </Box>
  );
};

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: '100%',
  marginLeft: theme.spacing(7),
  width: 2,
}));
