import { Box, FormControlLabel, Grid, Radio, Typography } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormRadioGroup } from 'redesign';
import { RadioOption } from 'redesign/forms/radio-group/radio.types';
import {
  ShiftClaimCancellationReason,
  ShiftClaimCancellationReasonLabels,
} from 'shared/components/shift-detail-modal/hooks/shared/cancelation-dialog/cancelation-dialog.types';
import { BulkCancelPlacementForm, CancellationType } from './bulk-cancel-placements.types';

export const BulkCancelFormOptions = () => {
  const { setValue, watch, control } = useFormContext<BulkCancelPlacementForm>();
  const [cancelWithReason, setCancelWithReason] = useState(false);

  const items: RadioOption[] = Object.entries(ShiftClaimCancellationReason).map(([key, value]) => ({
    label: ShiftClaimCancellationReasonLabels[key as keyof typeof ShiftClaimCancellationReason],
    value,
  }));

  const handleCancelationTypeChange = (value: CancellationType) => {
    setValue('cancellationType', value);
    if (value === CancellationType.HQ) {
      setCancelWithReason(true);
    } else if (value === CancellationType.OGP) {
      setCancelWithReason(false);
      setValue('reason', undefined);
    } else if (value === CancellationType.OGP_NO_PAYOUT) {
      setCancelWithReason(false);
      setValue('reason', undefined);
    }
  };
  return (
    <Grid container padding={10} spacing={4}>
      <Grid padding={4} item xs={12}>
        <Typography variant="h3">Annulering van plaatsingen</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          value={CancellationType.HQ}
          label={<Typography variant="h2">Annuleren met reden</Typography>}
          control={
            <Radio
              onChange={() => handleCancelationTypeChange(CancellationType.HQ)}
              checked={watch('cancellationType') === CancellationType.HQ}
            />
          }
        />
        <Box marginLeft={10}>
          <FormRadioGroup
            name="reason"
            disabled={!cancelWithReason}
            control={control}
            items={items}
          ></FormRadioGroup>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          value={CancellationType.OGP}
          label={<Typography variant="h2">Annuleren als opdrachtgever</Typography>}
          control={
            <Radio
              onChange={() => handleCancelationTypeChange(CancellationType.OGP)}
              checked={watch('cancellationType') === CancellationType.OGP}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          value={CancellationType.OGP_NO_PAYOUT}
          label={
            <Typography variant="h2">
              Annuleer alle plaatsingen waarbij de 50%-regel niet van toepassing is
            </Typography>
          }
          control={
            <Radio
              onChange={() => handleCancelationTypeChange(CancellationType.OGP_NO_PAYOUT)}
              checked={watch('cancellationType') === CancellationType.OGP_NO_PAYOUT}
            />
          }
        />
      </Grid>
    </Grid>
  );
};
