import { isApplicant } from 'shared/components/shift-detail-modal/components/tabs/helpers';
import { useChooseFlexWorkerFlexPoolsDialog } from 'shared/components/dialogs/flex-pools-for-flex-worker-dialog/use-choose-flex-worker-flex-pool-dialog';
import { useHqUpdateFlexWorkerFlexPools } from 'shared/components/dialogs/flex-pools-for-flex-worker-dialog/hooks/hq/use-hq-update-flex-worker-flex-pools';
import { useHqGetFlexWorkerFlexPools } from 'shared/components/dialogs/flex-pools-for-flex-worker-dialog/hooks/hq/use-hq-flex-worker-flex-pools';
import { useHqSetNoShow } from 'shared/components/dialogs/no-show-dialog/query-hooks/hq/use-hq-set-no-show';
import { useNoShowDialog } from 'shared/components/dialogs/no-show-dialog/use-no-show-dialog';
import {
  ShiftClaimant,
  ShiftDetailData,
} from 'shared/components/shift-detail-modal/components/tabs/claimants/claimant-table.types';
import { HQDownloadContractAction } from './claimant-actions/download-contract-action';
import { HQNoShowAction } from './claimant-actions/no-show-action';
import { HQCancelShiftClaimAction } from './claimant-actions/cancel-shift-claim-action';
import { HQCancelShiftClaimWithReasonAction } from './claimant-actions/cancel-shift-claim-with-reason-action';
import { OpenFlexPoolsAction } from '../../shared/claimant-actions/open-flex-pools-action';

const useHqGetClaimantActions = (companyId?: string) => {
  const {
    open: openNoShow,
    Element: NoShowDialog,
    setIsFlexWorker,
  } = useNoShowDialog(useHqSetNoShow);
  const { open: openChooseFlexWorkerFlexPools, Element: ChooseFlexWorkerFlexPools } =
    useChooseFlexWorkerFlexPoolsDialog(
      useHqUpdateFlexWorkerFlexPools,
      useHqGetFlexWorkerFlexPools,
      companyId
    );

  const getClaimantActions = (candidate: ShiftClaimant, shiftData: ShiftDetailData) => {
    const actions: React.ReactNode[] = [];

    if (isApplicant(candidate)) {
      return actions;
    }

    if (candidate.state !== 'canceled') {
      actions.push(
        <HQCancelShiftClaimWithReasonAction candidate={candidate} key="cancel-with-reason" />
      );
      actions.push(
        <HQCancelShiftClaimAction candidate={candidate} shiftData={shiftData} key="cancel" />
      );
    }

    actions.push(
      <HQNoShowAction
        candidate={candidate}
        shiftData={shiftData}
        setIsFlexWorker={setIsFlexWorker}
        openNoShow={openNoShow}
        key="no-show"
      />
    );

    if (!candidate.isFlexWorker) {
      return actions;
    }

    actions.push(
      <OpenFlexPoolsAction
        open={openChooseFlexWorkerFlexPools}
        shiftData={shiftData}
        candidate={candidate}
        key="flex-pools"
      />
    );

    actions.push(<HQDownloadContractAction candidate={candidate} key="contract" />);

    return actions;
  };

  return { getClaimantActions, NoShowDialog, ChooseFlexWorkerFlexPools };
};

export { useHqGetClaimantActions };
