import { Box, styled } from '@mui/material';
import { DateTime } from 'luxon';
import { DepartmentSelect } from 'ogp/components/views/planning/components';
import { TableDateControl } from './table-date-control/table-date-control';
import { CreateNewShiftButton } from '../components/create-new-shift-button/create-new-shift-button';
import { ExportShiftsButton } from '../components/export-shifts-button/export-shifts-button';
import { PlanningViewSwitcher } from '../components/switch-view/planning-view-switcher';

type GridPlanningHeadProps = {
  openExportModal: () => void;
  switchCalendarView: () => void;
  date: DateTime;
};

export const TablePlanningToolbox = ({
  openExportModal,
  switchCalendarView,
  date,
}: GridPlanningHeadProps) => {
  return (
    <Header>
      <CreateNewShiftButton />
      <TableDateControl date={date} />
      <ButtonSection>
        <PlanningViewSwitcher switchView={switchCalendarView} type="table" />
        <ExportShiftsButton openExportModel={openExportModal} />
        <DepartmentSelect />
      </ButtonSection>
    </Header>
  );
};

const Header = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  marginTop: theme.spacing(8),
  gap: theme.spacing(8),
  width: '100%',
  justifyContent: 'space-between',
}));

const ButtonSection = styled(Box)(({ theme }) => ({
  gap: theme.spacing(8),
  display: 'flex',
  flexShrink: 0,
}));
