import { useWizardContext } from 'redesign/wizard/wizard-provider';
import { useAlert } from 'shared/components/alerts';
import { RenderUnsettledUI, SmallError, SmallLoader } from 'shared/components';
import { useBulkActionResultContext } from 'shared/providers/bulk-action-provider';
import { useGetShiftsPartners } from './hooks/use-get-shifts-partners';
import { useBulkOpenShiftsToPartners } from './hooks/use-bulk-open-shifts-to-partners';
import { BulkForwardToPartnerDialogForm } from './dialog-components/dialog-form';
import { BulkForwardToShiftsFormInput } from './bulk-forward-to-partner-sidebar';

type BulkForwardToPartnerForm = {
  shiftIds: string[];
  setIsBulkForwardToPartnerOpen: (isOpen: boolean) => void;
};

export const BulkShiftForwardToPartnerFormProvider = ({
  shiftIds,
  setIsBulkForwardToPartnerOpen,
}: BulkForwardToPartnerForm) => {
  const getShiftEligiblePartnersQuery = useGetShiftsPartners(shiftIds);
  const mutation = useBulkOpenShiftsToPartners();
  const { alertError } = useAlert();
  const { nextStep } = useWizardContext();
  const { selectedIds, setMutationResult } = useBulkActionResultContext();

  const onSubmit = (data: BulkForwardToShiftsFormInput) => {
    mutation.mutate(
      {
        shiftIds: selectedIds,
        partnerIds: data.partners.map((partner) => partner.id),
      },
      {
        onSuccess: (data) => {
          setMutationResult(data);
          nextStep();
        },
        onError: (e) => alertError(e),
      }
    );
  };

  if (getShiftEligiblePartnersQuery.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={getShiftEligiblePartnersQuery}
      />
    );
  }
  return (
    <BulkForwardToPartnerDialogForm
      availablePartners={getShiftEligiblePartnersQuery.data}
      numberOfShifts={shiftIds.length}
      closeDialog={() => setIsBulkForwardToPartnerOpen(false)}
      onSubmit={onSubmit}
    />
  );
};
