import { RenderUnsettledUI } from 'shared/components';
import { useGetCompanies } from 'hq/hooks/queryHooks/companies/use-get-companies';
import { useParams } from 'react-router-dom';
import { useGetWorkDetails } from 'shared/components/work-forms/details/hooks/use-get-work-details';
import { HQProjectFormTypeEdit } from 'shared/components/project-forms/types/project-form.types';
import { ProjectEditFormPage } from './form/project-edit-form-page';
import { invariant } from '../../../../../../../shared/utils/utils';
import { useGetProjectDetail } from '../../../../../../../shared/components/planner/project-detail-modal/detail/applications/hooks/use-get-project-detail';
import { useGetCompanyBasicInfo } from '../../../../../../../shared/components/planner/project-detail-modal/detail/applications/hooks/use-get-company-basic-info';

export const ProjectEditDataProvider = () => {
  const companiesQuery = useGetCompanies();

  const { id: projectId } = useParams<'id'>();
  invariant(projectId, 'Project ID should be defined');

  const projectQuery = useGetProjectDetail(projectId);
  const projectCompanyQuery = useGetCompanyBasicInfo(projectId);
  const workDetailsQuery = useGetWorkDetails(projectId);

  if (companiesQuery.status !== 'success') {
    return <RenderUnsettledUI data={companiesQuery} />;
  }

  if (projectQuery.status !== 'success') {
    return <RenderUnsettledUI data={projectQuery} />;
  }

  if (projectCompanyQuery.status !== 'success') {
    return <RenderUnsettledUI data={projectCompanyQuery} />;
  }

  if (workDetailsQuery.status !== 'success') {
    return <RenderUnsettledUI data={workDetailsQuery} />;
  }

  const defaultValues: HQProjectFormTypeEdit = {
    companyId: projectCompanyQuery.data.id,
    departmentId: projectQuery.data.department.id,
    startDate: projectQuery.data.startDate,
    endDate: projectQuery.data.endDate,
    hourlyRate: (projectQuery.data.freelanceProperties?.hourlyRateCents ?? 0) / 100,
    weeklyHours: projectQuery.data.weekHourVolume,
    isTempwork: !!projectQuery.data.tempWorkProperties,
    isFreelance: !!projectQuery.data.freelanceProperties,
  };

  const isProjectWithActivePlacement = projectQuery.data.placements.some(
    (placement) => placement.state === 'active'
  );

  return (
    <ProjectEditFormPage
      companies={companiesQuery.data}
      defaultValues={defaultValues}
      successMessage={`Project geüpdatet`}
      pageTitle={`Update project`}
      projectId={projectId}
      isProjectWithActivePlacement={isProjectWithActivePlacement}
      workDetails={workDetailsQuery.data}
    />
  );
};
