import { IconCloseCircle } from 'assets/img';
import { useDialog } from 'ogp/providers/dialog-provider';
import { useState } from 'react';
import { ContextMenuItem } from 'redesign';
import { shiftAlerts, useAlert } from 'shared/components/alerts';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { useHqCancelShiftClaim } from 'shared/components/shift-detail-modal/query-hooks/hq/use-hq-cancel-shift-claim';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { WorkerEmploymentType } from '@types';
import { getHqCancelationDialog } from '../../../shared/cancelation-dialog/cancelation-dialog';
import { ShiftClaimCancellationReason } from '../../../shared/cancelation-dialog/cancelation-dialog.types';

export const HQCancelShiftClaimWithReasonAction = ({
  candidate,
}: {
  candidate: {
    claimId: string;
    isFlexWorker: boolean;
    employmentType: WorkerEmploymentType | undefined;
  };
}) => {
  const [cancelationReason, setCancelationReason] = useState<ShiftClaimCancellationReason>(
    ShiftClaimCancellationReason.SICKNESS
  );
  const { alertSuccess, alertError } = useAlert();
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const invalidateQuery = useInvalidateQuery();
  const cancelShiftClaim = useHqCancelShiftClaim();

  const handleCancelShiftClaim = async (candidate: {
    claimId: string;
    isFlexWorker: boolean;
    employmentType: WorkerEmploymentType | undefined;
  }) => {
    const dialogContent = getHqCancelationDialog(setCancelationReason);
    openDialog(dialogContent, () => {
      disableDialog();
      cancelShiftClaim.mutate(
        {
          shiftClaimId: candidate.claimId,
          payload: {
            cancelationReason,
          },
        },
        {
          onSuccess: () => {
            invalidateQuery(QUERY_KEYS_HQ.shiftPlanningShifts());
            invalidateQuery(QUERY_KEYS_HQ.shifts());
            invalidateQuery(QUERY_KEYS_HQ.placements());
            closeDialog();
            alertSuccess(shiftAlerts.success.unsubscribed);
          },
          onError: (e) => alertError(e),
          onSettled: () => {
            enableDialog();
          },
        }
      );
    });
  };

  return (
    <ContextMenuItem
      onClick={() => handleCancelShiftClaim(candidate)}
      key={`cancel-claim-${candidate.claimId}`}
    >
      <IconCloseCircle />
      Annuleren met reden
    </ContextMenuItem>
  );
};
