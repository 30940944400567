import { IconCloseCircle } from 'assets/img';
import { useCancelShiftClaim } from 'ogp/hooks/queryHooks/shiftclaims/use-cancel-shift-claim';
import { useDialog } from 'ogp/providers/dialog-provider';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { useState } from 'react';
import { ContextMenuItem } from 'redesign';
import { shiftAlerts, useAlert } from 'shared/components/alerts';
import {
  ShiftClaimant,
  ShiftDetailData,
} from 'shared/components/shift-detail-modal/components/tabs/claimants/claimant-table.types';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { getHqCancelationDialog } from '../../../shared/cancelation-dialog/cancelation-dialog';
import { ShiftClaimCancellationReason } from '../../../shared/cancelation-dialog/cancelation-dialog.types';

export const OGPCancelShiftClaimWithReasonAction = ({
  candidate,
  shiftData,
}: {
  candidate: ShiftClaimant;
  shiftData: ShiftDetailData;
}) => {
  const [cancelationReason, setCancelationReason] = useState<ShiftClaimCancellationReason>(
    ShiftClaimCancellationReason.SICKNESS
  );
  const { alertSuccess, alertError } = useAlert();
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const invalidateQuery = useInvalidateQuery();
  const cancelShiftClaim = useCancelShiftClaim();

  const handleCancelShiftClaim = async (shiftData: ShiftDetailData, candidate: ShiftClaimant) => {
    const dialogContent = getHqCancelationDialog(setCancelationReason);
    openDialog(dialogContent, () => {
      disableDialog();
      cancelShiftClaim.mutate(
        {
          shiftClaimId: candidate.claimId,
          payload: {
            cancelationReason,
          },
        },
        {
          onSuccess: () => {
            invalidateQuery(QUERY_KEYS_OGP.shift(shiftData.id));
            closeDialog();
            alertSuccess(shiftAlerts.success.unsubscribed);
          },
          onError: (e) => alertError(e),
          onSettled: () => {
            enableDialog();
          },
        }
      );
    });
  };

  return (
    <ContextMenuItem
      onClick={() => handleCancelShiftClaim(shiftData, candidate)}
      key={`cancel-claim-${candidate.claimId}`}
    >
      <IconCloseCircle />
      Annuleren met reden
    </ContextMenuItem>
  );
};
