import { Grid } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToolboxFormCreate } from 'shared/components/common/toolbox-form-create';
import { Header, Page } from 'redesign';
import { useAlert } from 'shared/components/alerts';
import { CompanyInput } from 'shared/components/shift-forms/components/forms/shared/inputs/company-input';
import { hqRoutes } from 'hq/Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { stringifyFilters } from 'shared/utils/stringify-filters';
import { GetCompaniesResponse } from 'hq/services/company-service.types';
import { HQProjectFormTypeEdit } from 'shared/components/project-forms/types/project-form.types';
import { HQProjectPayloadEdit } from 'hq/services/project-planning-service.types';
import { DetailsForWorkTemplate } from 'shared/components/work-forms/details/work-form-details.types';
import { ProjectEditFormProvider } from './project-edit-form-provider';
import { useProjectPlanningPage } from '../../../table/hooks/use-project-planning';
import { useEditProject } from '../../../hooks/use-edit-project';

type ProjectCreateProps = {
  companies: GetCompaniesResponse[];
  defaultValues?: Partial<HQProjectFormTypeEdit>;
  successMessage: string;
  pageTitle: string;
  projectId: string;
  isProjectWithActivePlacement: boolean;
  workDetails: DetailsForWorkTemplate;
};

export const ProjectEditFormPage = ({
  companies,
  defaultValues,
  successMessage,
  pageTitle,
  projectId,
  isProjectWithActivePlacement,
  workDetails,
}: ProjectCreateProps) => {
  const { alertSuccess, alertError } = useAlert();
  const formMethods = useForm<HQProjectFormTypeEdit>({
    defaultValues,
  });
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const mutation = useEditProject();
  const { params } = useProjectPlanningPage();

  const { companyId } = formMethods.watch();

  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit((data: HQProjectFormTypeEdit) => {
      const payload: HQProjectPayloadEdit = {
        startDate: data.startDate,
        endDate: data.endDate,
        weekHourVolume: +data.weeklyHours,
        freelanceProperties: data.isFreelance
          ? {
              hourlyRateCents: Math.round(+data.hourlyRate * 100),
            }
          : undefined,
        isTempWork: data.isTempwork,
      };
      mutation.mutate(
        { projectId, projectData: payload },
        {
          onSuccess: () => {
            alertSuccess(successMessage);
            navigate(
              generatePath(
                hqRoutes.PlannerProject,
                {},
                { ...stringifyFilters(params), projectDetailId: projectId }
              )
            );
          },
          onError: (e) => alertError(e),
        }
      );
    })();
  }, [projectId]);

  const onCancel = useCallback(
    () => navigate(generatePath(hqRoutes.PlannerProject, stringifyFilters(params))),
    [params, generatePath, navigate]
  );

  const memoizedCompanies = useMemo(
    () => companies.map((i) => ({ label: i.name, value: i.id })),
    [companies]
  );

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={pageTitle} />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={onCancel}
          />
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <CompanyInput options={memoizedCompanies} disabled={true}></CompanyInput>
            {companyId ? (
              <ProjectEditFormProvider
                companyId={companyId}
                isProjectWithActivePlacement={isProjectWithActivePlacement}
                workDetails={workDetails}
              />
            ) : null}
          </Grid>
        </Grid>
      </Page>
    </FormProvider>
  );
};
