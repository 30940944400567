import { IconAccessTime } from 'assets/img';
import type { Column } from 'react-table';
import { Box, styled, Tooltip, Typography } from '@mui/material';
import { ContextMenuButton } from 'redesign';
import { HQCancelShiftClaimWithReasonAction } from 'shared/components/shift-detail-modal/hooks/hq/actions/claimant-actions/cancel-shift-claim-with-reason-action';
import { HQCancelShiftClaimAction } from 'shared/components/shift-detail-modal/hooks/hq/actions/claimant-actions/cancel-shift-claim-action';
import { mapPlacementStateToCopy } from './parse-table-data';
import { PlacementsCellProp, PlacementsColumnDefinition } from '../placements.types';

const getPlacementColumnDefinition: () => Column<PlacementsColumnDefinition>[] = () => {
  const workerName: Column<PlacementsColumnDefinition> = {
    Header: () => <Header>Werker</Header>,
    accessor: 'workerName',
    Cell: WorkerCell,
  };

  const companyBranchOfficeInfo: Column<PlacementsColumnDefinition> = {
    Header: () => <Header>Bedrijf en Vestigingen</Header>,
    accessor: 'companyBranchOfficeInfo',
    Cell: CompanyBranchOfficeInfoCell,
  };

  const departmentName: Column<PlacementsColumnDefinition> = {
    Header: () => <Header>Afdeling</Header>,
    accessor: 'departmentName',
    width: '150px',
    Cell: DepartmentCell,
  };

  const jobName: Column<PlacementsColumnDefinition> = {
    Header: () => <Header>Functie</Header>,
    accessor: 'jobName',
    width: '150px',
    Cell: JobCell,
  };

  const jobType: Column<PlacementsColumnDefinition> = {
    Header: () => <Header>Functiegroep</Header>,
    accessor: 'jobType',
    width: '150px',
    Cell: JobTypeCell,
  };

  const workerEmploymentType: Column<PlacementsColumnDefinition> = {
    Header: () => <Header>Dienstverband functie</Header>,
    accessor: 'workerEmploymentType',
    width: '150px',
    Cell: WorkerEmploymentTypeCell,
  };

  const scheduledTime: Column<PlacementsColumnDefinition> = {
    Header: () => <Header>Datum & tijd</Header>,
    accessor: 'dateTime',
    Cell: DateTimeCell,
  };

  const state: Column<PlacementsColumnDefinition> = {
    Header: () => <Header>Status</Header>,
    accessor: 'state',
    Cell: StateCell,
    width: '150px',
  };
  const actions: Column<PlacementsColumnDefinition> = {
    Header: () => <Header>Actie</Header>,
    accessor: 'actions',
    Cell: ActionCell,
    width: '100px',
  };

  return [
    workerName,
    companyBranchOfficeInfo,
    departmentName,
    jobName,
    jobType,
    workerEmploymentType,
    scheduledTime,
    state,
    actions,
  ];
};

const Header = ({ children }: React.PropsWithChildren<unknown>) => (
  <Typography variant="body2" style={{ fontWeight: 'bold' }}>
    {children}
  </Typography>
);

const WorkerCell = (cell: PlacementsCellProp<'workerName'>) => {
  return (
    <Typography variant="body1" noWrap>
      {cell.value}
    </Typography>
  );
};

const CompanyBranchOfficeInfoCell = (cell: PlacementsCellProp<'companyBranchOfficeInfo'>) => {
  return (
    <Tooltip
      style={{ display: 'flex', gap: '3px' }}
      title={
        <Box>
          {cell.value.companyName}
          <br />
          {cell.value.branchOfficeName}
        </Box>
      }
    >
      <BoxInfo>
        <TypographyWithPreLine variant="body1" noWrap>
          {`${cell.value.companyName} \n ${cell.value.branchOfficeName}`}
        </TypographyWithPreLine>
      </BoxInfo>
    </Tooltip>
  );
};

const JobCell = (cell: PlacementsCellProp<'jobName'>) => {
  return (
    <Typography variant="body1" noWrap>
      {cell.value}
    </Typography>
  );
};

const JobTypeCell = (cell: PlacementsCellProp<'jobType'>) => {
  return (
    <Typography variant="body1" noWrap>
      {cell.value}
    </Typography>
  );
};

const DepartmentCell = (cell: PlacementsCellProp<'departmentName'>) => {
  return (
    <Tooltip placement="bottom-start" title={cell.value}>
      <Typography variant="body1" noWrap>
        {cell.value}
      </Typography>
    </Tooltip>
  );
};
const WorkerEmploymentTypeCell = (cell: PlacementsCellProp<'workerEmploymentType'>) => {
  return (
    <Typography variant="body1" noWrap>
      {cell.value}
    </Typography>
  );
};

const StateCell = (cell: PlacementsCellProp<'state'>) => {
  return <Typography variant="body1">{cell.value}</Typography>;
};

const DateTimeCell = (cell: PlacementsCellProp<'dateTime'>) => {
  return (
    <BoxInfo>
      <Typography variant="body1" color="secondary">
        {cell.value.fromDateTime.toFormat('dd MMM yyyy')}
      </Typography>
      <BoxTime>
        <IconAccessTime />
        <Typography variant="body1">
          {cell.value.fromDateTime.toFormat('HH:mm') +
            '-' +
            cell.value.toDateTime.toFormat('HH:mm')}
        </Typography>
      </BoxTime>
    </BoxInfo>
  );
};

const ActionCell = ({ value }: PlacementsCellProp<'actions'>) => {
  const candidate = {
    claimId: value.placementId,
    isFlexWorker: value.worker.isFlexWorker,
    employmentType: value.worker.employmentType,
  };

  const shiftData = {
    mutableUntilDate: value.mutableUntilDate,
    isFreelance: value.isFreelance,
  };

  const actions: React.ReactNode[] = [];

  if (value.placementState == 'accepted') {
    actions.push(
      <HQCancelShiftClaimWithReasonAction candidate={candidate} key="cancel-with-reason" />
    );
    actions.push(
      <HQCancelShiftClaimAction candidate={candidate} shiftData={shiftData} key="cancel" />
    );
  } else {
    actions.push(
      `Plaatsing kan niet worden geannuleerd, omdat het de volgende status heeft: ${mapPlacementStateToCopy(value.placementState)}.`
    );
  }

  return (
    <ContextMenuButton menuId={`placements-actions-menu-${value.worker.lastName}`}>
      {actions}
    </ContextMenuButton>
  );
};

const TypographyWithPreLine = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-line',
  color: theme.palette.text.secondary,
}));

const BoxInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  whiteSpace: 'nowrap',
  alignItems: 'flexStart',
}));
const BoxTime = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'end',
  gap: theme.spacing(0.5),
}));

export { getPlacementColumnDefinition };
