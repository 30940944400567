import { useState } from 'react';
import { DateTime } from 'luxon';
import { PlanningToolbox } from './planning-toolbox/planning-toolbox';
import { ExportModal } from './planning-toolbox/components/export-drawer/export-drawer';
import { PlanningViewType } from '../planning.types';

export type PlanningHeaderPops = {
  date: DateTime;
  type: PlanningViewType;
  setView: (val: PlanningViewType) => void;
};

export const PlanningHeader = ({ date, type, setView }: PlanningHeaderPops) => {
  const [exportModalOpen, setExportModalOpen] = useState(false);

  return (
    <>
      <ExportModal exportModalOpen={exportModalOpen} setExportModalOpen={setExportModalOpen} />
      <PlanningToolbox
        type={type}
        setView={setView}
        date={date}
        openExportModal={() => setExportModalOpen(true)}
      />
    </>
  );
};
