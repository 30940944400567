import {
  usePlanningDateRange,
  useReadPlanningPathParams,
  useUpdatePlanningPath,
} from 'ogp/hooks/planning';
import { usePageProvider } from 'ogp/layouts';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RenderUnsettledUI } from 'shared/components';
import { PlanningShiftGrid } from './planning-shift-grid';
import { useGetShiftsPlanning } from '../../hooks/use-get-shifts-planning';

export const PlanningShiftGridProvider = () => {
  const { startDate, endDate } = usePlanningDateRange();
  const [{ filters }] = usePageProvider();
  const navigate = useNavigate();
  const { date: paramsDate } = useReadPlanningPathParams();
  const updatePlanningPath = useUpdatePlanningPath();

  const planningQuery = useGetShiftsPlanning({
    departmentId: filters.departmentId,
    fromDate: startDate.toISODate(),
    toDate: endDate.toISODate(),
  });

  useEffect(() => {
    if (!paramsDate || !paramsDate.startOf('week').isValid) {
      const path = updatePlanningPath({ date: DateTime.now() });
      navigate(path, { replace: true });
    }
  }, [paramsDate, navigate, updatePlanningPath]);

  if (planningQuery.status !== 'success') {
    return <RenderUnsettledUI data={planningQuery} />;
  }

  return <PlanningShiftGrid data={planningQuery.data} />;
};
