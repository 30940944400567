import { RenderUnsettledUI } from 'shared/components';
import { useGetDepartmentsAsSelect } from 'ogp/components/views/management/departments/hooks';
import { useGetCompanyEmploymentTypes } from 'ogp/hooks/queryHooks/companies/use-get-company-employment-types';
import { ProjectCreateFormPage } from './form/project-create-form-page';

export const ProjectCreateDataProvider = () => {
  const departmentsQuery = useGetDepartmentsAsSelect({ includeArchived: false });
  const employmentTypeOfCompanyQuery = useGetCompanyEmploymentTypes();

  if (employmentTypeOfCompanyQuery.status !== 'success') {
    return <RenderUnsettledUI data={employmentTypeOfCompanyQuery} />;
  }
  if (departmentsQuery.status !== 'success') {
    return <RenderUnsettledUI data={departmentsQuery} />;
  }

  return (
    <ProjectCreateFormPage
      successMessage={`Project aangemaakt`}
      pageTitle={`Maak nieuw project aan`}
      departments={departmentsQuery.data}
      companyEmploymentType={employmentTypeOfCompanyQuery.data}
    />
  );
};
