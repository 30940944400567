import { BreakOption } from '@types';
import { ShiftDetailResponse } from 'shared/services/shift-service-types';
import { HqApiService } from './hqApiService';
import {
  BulkShiftActionDataResponse,
  GetShiftCreateDataResponse,
  GetShiftEligiblePartnerResponse,
  GetShiftPartnerResponse,
  HQCreateShiftPayload,
  HQDuplicateShiftPayload,
  HQMultiplyShiftPayload,
  HQUpdateShiftPayload,
  OpenShiftToPartnersRequest,
} from './shift-service.types';
import { ShiftBulkArchivePayload, ShiftBulkEditPayload } from './shift-planning-service.types';
class ShiftService {
  constructor(private readonly hqApiService: HqApiService) {}

  public getShift = async (shiftId: string) => {
    const response = await this.hqApiService.get<ShiftDetailResponse>({
      path: `shifts/${shiftId}`,
    });
    return response;
  };

  /**
   * Get breakoptions
   */
  public getBreakOptions = async () => {
    const response = await this.hqApiService.get<{ breakOptions: BreakOption[] }>({
      path: `shifts/get-break-options`,
    });

    return response;
  };

  public getShiftCreateData = async (companyId: string) => {
    const response = await this.hqApiService.get<GetShiftCreateDataResponse>({
      path: `shifts/get-shift-create-data`,
      params: { companyId },
    });

    return response;
  };

  public getShiftPartners = async (shiftId: string) => {
    const response = await this.hqApiService.get<GetShiftPartnerResponse>({
      path: `shifts/${shiftId}/partners`,
    });
    return response;
  };

  public getShiftEligiblePartners = async (shiftId: string) => {
    const response = await this.hqApiService.get<GetShiftEligiblePartnerResponse>({
      path: `shifts/${shiftId}/eligible-partners`,
    });
    return response;
  };

  public archiveShift = async (shiftId: string) => {
    return await this.hqApiService.update({
      path: `shifts/${shiftId}/archive`,
    });
  };

  public createShift = async (shift: HQCreateShiftPayload) => {
    return await this.hqApiService.create<string>({
      path: `shifts`,
      body: shift,
    });
  };

  public updateShift = async (shift: HQUpdateShiftPayload) => {
    return await this.hqApiService.update({
      path: `shifts/${shift.id}`,
      body: shift,
    });
  };

  public duplicateShift = async (shift: HQDuplicateShiftPayload) => {
    return await this.hqApiService.create<string>({
      path: `shifts/duplicate`,
      body: shift,
    });
  };

  public multiplyShift = async (params: HQMultiplyShiftPayload) => {
    return await this.hqApiService.create({
      path: `shifts/${params.id}/multiply`,
      body: params,
    });
  };

  public openShiftToPartners = async (data: OpenShiftToPartnersRequest) => {
    return await this.hqApiService.update({
      path: `shifts/shift-partners`,
      body: data,
    });
  };

  public bulkUpdateShifts = async (
    data: ShiftBulkEditPayload
  ): Promise<BulkShiftActionDataResponse> => {
    return await this.hqApiService.update({
      path: `shifts/bulk-update`,
      body: data,
    });
  };

  public bulkArchiveShifts = async (
    data: ShiftBulkArchivePayload
  ): Promise<BulkShiftActionDataResponse> => {
    return await this.hqApiService.update({
      path: `shifts/bulk-archive`,
      body: data,
    });
  };
}

export { ShiftService };
