import { ProjectForm } from 'shared/components/project-forms/components/project-form';
import { RenderUnsettledUI } from 'shared/components';
import { DetailsForWorkTemplate } from 'shared/components/work-forms/details/work-form-details.types';
import { useGetCompanyEmploymentTypes } from 'hq/hooks/queryHooks/companies/use-get-company-employment-types';
import { useGetDepartmentsAsSelect } from '../../hooks/use-get-departments';

type Props = {
  companyId: string;
  workDetails: DetailsForWorkTemplate;
};

export const ProjectDuplicateFormProvider = ({ companyId, workDetails }: Props) => {
  const departmentsQuery = useGetDepartmentsAsSelect(companyId, { includeArchived: false });
  const employmentTypeOfCompanyQuery = useGetCompanyEmploymentTypes(companyId);

  if (employmentTypeOfCompanyQuery.status !== 'success') {
    return <RenderUnsettledUI data={employmentTypeOfCompanyQuery} />;
  }

  if (departmentsQuery.status !== 'success') {
    return <RenderUnsettledUI data={departmentsQuery} />;
  }

  return (
    <ProjectForm
      mode="duplicate"
      isProjectWithActivePlacement={false}
      departments={departmentsQuery.data}
      workDetails={workDetails}
      companyEmploymentType={employmentTypeOfCompanyQuery.data}
    />
  );
};
