import { Drawer } from '@mui/material';
import { useState } from 'react';
import { WizardProvider } from 'redesign/wizard/wizard-provider';
import { BulkActionProvider } from 'shared/providers/bulk-action-provider';
import { BulkCancelPlacementsResponse } from 'hq/services/placement-service.types';
import { BulkCancelForm } from './bulk-cancel-form';
import { BulkCancelResult } from './bulk-cancel-result/bulk-cancel-result';

type BulkCancelSidebarProps = {
  selectedPlacementIds: string[];
  isBulkSideBarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
};

export const BulkCancelSidebar = ({
  selectedPlacementIds,
  isBulkSideBarOpen,
  setIsSidebarOpen,
}: BulkCancelSidebarProps) => {
  const [mutationResult, setMutationResult] = useState<BulkCancelPlacementsResponse>({
    fulfilled: [],
    failure: [],
  });

  return (
    <BulkActionProvider
      setMutationResult={setMutationResult}
      selectedIds={selectedPlacementIds}
      failure={mutationResult.failure}
      fulfilled={mutationResult.fulfilled}
    >
      <Drawer anchor="right" open={isBulkSideBarOpen} PaperProps={{ style: { width: 600 } }}>
        <WizardProvider onClose={() => setIsSidebarOpen(false)}>
          <BulkCancelForm />
          <BulkCancelResult />
        </WizardProvider>
      </Drawer>
    </BulkActionProvider>
  );
};
