import { FormProvider, useForm } from 'react-hook-form';
import { Box, Grid, styled, Typography } from '@mui/material';
import { useCallback } from 'react';
import { LwButton, LwFormNumberInput, LwFormSelect } from 'redesign';
import { useNavigate } from 'react-router-dom';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { usePartnerRelativePath } from 'partner/shared/hooks/use-partner-relative-path';
import { getExpenseConfigForEmploymentType, getExpenseValueFromForm } from 'shared/utils/expenses';
import { PARTNER_PATHS } from 'partner/paths';
import { DateTime, Duration, Interval } from 'luxon';
import { RenderUnsettledUI } from 'shared/components';
import { ShiftDateTimeInputs } from 'shared/components/shift-forms/components/forms/shared/inputs/date-time-inputs';
import { WorkerEmploymentType } from '@types';
import { ShiftCheckoutFormData } from './shift-checkout-form.types';
import { useGetBreakOptions } from './hooks/use-get-break-options';
import { useSubmitCheckoutForm } from './hooks/use-submit-checkout-form';
import { breakOptionsSelectInputMapper } from '../../../../../shared/components/shift-forms/components/forms/shared/inputs/break-minutes-input';

type Props = {
  shiftId: string;
  flexWorkerId: string;
  workerEmploymentType: WorkerEmploymentType;
  defaultValues: ShiftCheckoutFormData;
};

export const ShiftCheckoutForm = ({
  shiftId,
  flexWorkerId,
  workerEmploymentType,
  defaultValues,
}: Props) => {
  const formMethods = useForm<ShiftCheckoutFormData>({ defaultValues });
  const { handleSubmit, control, watch } = formMethods;
  const onSubmit = useSubmitCheckoutForm();
  const onFormSubmit = useCallback(
    (data: ShiftCheckoutFormData) => {
      onSubmit({
        ...data,
        shiftId,
        flexWorkerId,
        expenses: data.expenses.map((expense) => ({
          ...expense,
          amount: getExpenseValueFromForm(expense),
        })),
      });
    },
    [flexWorkerId, onSubmit, shiftId]
  );
  const { allowExpenses } = useFeatureFlagContext();

  const navigate = useNavigate();
  const generatePath = usePartnerRelativePath();

  const breakOptionsQuery = useGetBreakOptions();

  if (breakOptionsQuery.status !== 'success') {
    return <RenderUnsettledUI data={breakOptionsQuery} />;
  }

  const breakMinutesOptions = breakOptionsSelectInputMapper(breakOptionsQuery.data);

  const shiftStartProposed = DateTime.fromISO(watch('startDate') + 'T' + watch('startTime'));
  const shiftEndProposed = DateTime.fromISO(watch('endDate') + 'T' + watch('endTime'));
  const proposedBreakDuration = Duration.fromObject({ minutes: watch('breakMinutes') });
  const proposedShiftLength = Interval.fromDateTimes(
    shiftStartProposed,
    shiftEndProposed
  ).toDuration(['hours', 'minutes']);
  const totalProposedShiftLength = proposedShiftLength
    .minus(proposedBreakDuration)
    .normalize()
    // calling normalize twice seems weird, but Luxon does not manage to handle the negative minutes once they overlap one hour (in this case when break time is 90 minutes)
    // TODO: check if this works once Luxon gets updated to later versions
    .normalize();

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <FormContainer aria-label="Shift details form">
          <InputsContainer>
            <ShiftDateTimeInputs
              disabled={false}
              allowDateBeforeToday={true}
              allowDateAfterToday={false}
              mode="checkout"
            />
            <Box marginTop={4}>
              <LwFormSelect
                name="breakMinutes"
                label="Pauze"
                options={breakMinutesOptions}
                control={control}
                rules={{
                  validate: (breakMinutes) =>
                    Duration.fromObject({ minutes: Number(breakMinutes) }) >= proposedShiftLength
                      ? 'De pauze mag niet langer zijn dan de dienst'
                      : undefined,
                }}
              />
            </Box>

            {allowExpenses ? (
              <>
                {getExpenseConfigForEmploymentType(workerEmploymentType).map((exp, i) => (
                  <Grid key={exp.id} item xs={12}>
                    <LwFormNumberInput
                      name={`expenses.${i}.amount`}
                      label={exp.formLabel}
                      control={control}
                    />
                  </Grid>
                ))}
              </>
            ) : null}

            <Box marginTop={4} aria-label="Total shift duration">
              {proposedShiftLength.isValid ? (
                <>
                  <Typography variant="subtitle1">Totaal</Typography>
                  <Typography variant="h6">{totalProposedShiftLength.toHuman()}</Typography>
                </>
              ) : (
                <ErrorMessage>Ongeldige invoer: {proposedShiftLength.invalidReason}</ErrorMessage>
              )}
              {proposedShiftLength > Duration.fromObject({ hours: 24 }) ? (
                <ErrorMessage>De dienst mag niet langer zijn dan 24 uur</ErrorMessage>
              ) : null}
            </Box>
          </InputsContainer>
          <ButtonsContainer>
            <LwButton
              color="secondary"
              onClick={() => navigate(generatePath(PARTNER_PATHS.Checkout))}
            >
              Terug
            </LwButton>
            <LwButton color="primary" type="submit">
              Indienen
            </LwButton>
          </ButtonsContainer>
        </FormContainer>
      </form>
    </FormProvider>
  );
};

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: `${theme.spacing(5)} 0`,
  gap: theme.spacing(2),
}));

const InputsContainer = styled(Grid)(() => ({
  width: '500px',
}));

const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  margin: `0 ${theme.spacing(10)}`,
}));

const ErrorMessage = styled(Box)(({ theme }) => ({
  color: theme.palette.error.main,
}));
