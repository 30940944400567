import { Box } from '@mui/material';
import { styled } from '@mui/styles';
import { DateTime } from 'luxon';
import { FormProvider, useForm } from 'react-hook-form';
import { DateInputs } from './date-inputs';

type DateControlProps = {
  date: DateTime;
};

export type TableDateForm = {
  startDate: string;
  endDate?: string;
};

export const TableDateControl = ({ date }: DateControlProps) => {
  const methods = useForm<TableDateForm>({
    mode: 'onChange',
    defaultValues: {
      startDate: date.toFormat('yyyy-MM-dd'),
      endDate: undefined,
    },
  });

  return (
    <FormProvider {...methods}>
      <StyledBox>
        <DateInputs />
      </StyledBox>
    </FormProvider>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '30%',
  gap: theme.spacing(8),
}));
