import { DateTime } from 'luxon';
import { Box, styled } from '@mui/material';
import { TablePlanningToolbox } from './table-planning-toolbox/table-planning-toolbox';
import { CalendarPlanningToolbox } from './calendar-planning-toolbox/calendar-planning-toolbox';
import { PlanningViewType } from '../../planning.types';

type PlanningToolboxProps = {
  type: PlanningViewType;
  setView: (val: PlanningViewType) => void;
  date: DateTime;
  openExportModal: () => void;
};

export const PlanningToolbox = ({ date, type, setView, openExportModal }: PlanningToolboxProps) => {
  return (
    <Container>
      {type === 'calendar' ? (
        <CalendarPlanningToolbox date={date} openExportModal={openExportModal} />
      ) : (
        <TablePlanningToolbox
          date={date}
          switchCalendarView={() => setView('calendar')}
          openExportModal={openExportModal}
        />
      )}
    </Container>
  );
};

const Container = styled(Box)(() => ({
  display: 'flex',
  height: '100px',
}));
