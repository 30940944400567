import { PlanningShiftGridProvider } from './planning-shift-grid/planning-shift-grid-provider';
import { PlanningViewType } from '../planning.types';

type PlanningContentProps = {
  type: PlanningViewType;
};

export const PlanningContent = ({ type }: PlanningContentProps) => {
  if (type === 'calendar') {
    return <PlanningShiftGridProvider />;
  }

  // todo return grid view
  return <></>;
};
