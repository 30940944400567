import { IconAdd } from 'assets/img';
import { LwButton } from 'redesign';

export const CreateNewShiftButton = () => {
  return (
    <LwButton to="create" color="primary" startIcon={<IconAdd />} iconColorMode="fill">
      Voeg shift toe
    </LwButton>
  );
};
