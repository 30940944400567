import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { BulkShiftActionDataResponse } from 'hq/services/shift-service.types';
import { shiftService } from '../../../../../services';
import { ShiftBulkArchivePayload } from '../../../../../services/shift-planning-service.types';
import { QUERY_KEYS_HQ } from '../../../../../query-keys';

type MutationOptions = UseMutationOptions<
  BulkShiftActionDataResponse,
  unknown,
  ShiftBulkArchivePayload
>;

export const useBulkArchiveShift = () => {
  const mutationFn = async (data: ShiftBulkArchivePayload) =>
    await shiftService.bulkArchiveShifts(data);
  const queryClient = useQueryClient();
  const options: MutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.shifts());
      queryClient.invalidateQueries(QUERY_KEYS_HQ.shiftPlanningShifts());
    },
  };

  return useMutation(mutationFn, options);
};
