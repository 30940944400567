import React from 'react';
import { LwButton } from 'redesign';

type ExportShiftsButtonProps = {
  openExportModel: () => void;
};

export const ExportShiftsButton = ({ openExportModel }: ExportShiftsButtonProps) => {
  return (
    <LwButton color="secondary" onClick={openExportModel}>
      Export
    </LwButton>
  );
};
