import { DateTime } from 'luxon';
import { idCheckAlerts, useAlert } from 'shared/components/alerts';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { Controller, useForm } from 'react-hook-form';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { useRejectFlexWorkerIdentityCheck } from 'hq/hooks/queryHooks/flexWorkers/useRejectFlexWorkerIdentityCheck';
import { useApproveFlexWorkerIdentityCheck } from 'hq/hooks/queryHooks/flexWorkers/useApproveFlexWorkerIdentityCheck';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { LwFormDate, LwFormInput, LwFormSelect } from 'redesign';
import { styled } from '@mui/styles';
import {
  FlexworkerIdentityVerificationForm,
  FreelanceIdCheckProps,
} from './flex-worker-id-check.types';
import { IDDocumentType, InputSelectOption } from '../../../../../../../types';

export const FreelanceIdCheck = (props: FreelanceIdCheckProps) => {
  const { identityVerificationId, flexWorkerId, workerIdentityInformation, closeModal } = props;
  const { allowRequiredVatForWorkActions } = useFeatureFlagContext();
  const invalidateQuery = useInvalidateQuery();
  const { alertError, alertSuccess } = useAlert();

  const isBSNFieldShown =
    allowRequiredVatForWorkActions === false &&
    workerIdentityInformation.vatNumber === undefined &&
    !workerIdentityInformation.bsn;

  const defaultValues = {
    vatNumber: workerIdentityInformation.vatNumber,
    firstName: workerIdentityInformation.firstName,
    lastName: workerIdentityInformation.lastName,
    bsn: workerIdentityInformation.bsn,
    documentType: workerIdentityInformation.documentType,
    documentNumber: workerIdentityInformation.documentNumber,
    issuanceDate: workerIdentityInformation.issuanceDate
      ? DateTime.fromISO(workerIdentityInformation.issuanceDate).toISODate()
      : undefined,
    expirationDate: workerIdentityInformation.expirationDate
      ? DateTime.fromISO(workerIdentityInformation.expirationDate).toISODate()
      : undefined,
    dateOfBirth: workerIdentityInformation.dateOfBirth
      ? DateTime.fromISO(workerIdentityInformation.dateOfBirth).toISODate()
      : undefined,
  };

  const { handleSubmit, control } = useForm<FlexworkerIdentityVerificationForm>({
    defaultValues,
  });

  const approveMutation = useApproveFlexWorkerIdentityCheck(identityVerificationId, {
    onSuccess: () => {
      alertSuccess(idCheckAlerts.success.approved);
      invalidateQuery(QUERY_KEYS_HQ.flexWorkers());
      invalidateQuery(QUERY_KEYS_HQ.flexWorker(flexWorkerId));
      invalidateQuery(QUERY_KEYS_HQ.flexWorkerIdCheck(flexWorkerId));
      closeModal();
    },
    onError: (e) => alertError(e),
  });
  const denyMutation = useRejectFlexWorkerIdentityCheck(identityVerificationId, {
    onSuccess: () => {
      alertSuccess(idCheckAlerts.success.reject);
      invalidateQuery(QUERY_KEYS_HQ.flexWorkers());
      invalidateQuery(QUERY_KEYS_HQ.flexWorker(flexWorkerId));
      invalidateQuery(QUERY_KEYS_HQ.flexWorkerIdCheck(flexWorkerId));
      closeModal();
    },
    onError: (e) => alertError(e),
  });

  const onApprove = (formData: FlexworkerIdentityVerificationForm) => {
    approveMutation.mutate({
      bsn: formData.bsn,
      firstName: formData.firstName,
      lastName: formData.lastName,
      documentType: formData.documentType,
      documentNumber: formData.documentNumber,
      issuanceDate: formData.issuanceDate,
      expirationDate: formData.expirationDate,
      dateOfBirth: formData.dateOfBirth,
    });
  };

  const onDeny = async () => {
    await denyMutation.mutateAsync();
  };

  const viewIdDocument = () => {
    window.open(workerIdentityInformation.idDocumentImage);
  };

  const documentTypeOptions: InputSelectOption[] = [
    { value: IDDocumentType.ID, label: 'ID' },
    { value: IDDocumentType.PASSPORT, label: 'Paspoort' },
    { value: IDDocumentType.RESIDENCE_PERMIT, label: 'Verblijfsvergunning' },
  ];

  return (
    <form onSubmit={handleSubmit(onApprove)}>
      {workerIdentityInformation.idDocumentImage ? (
        <Grid item xs={12}>
          <StyledButton onClick={viewIdDocument} variant="contained" size="large" color="primary">
            <Typography variant="body2">Bekijk identiteitsbewijs</Typography>
          </StyledButton>
        </Grid>
      ) : null}
      <Grid container item spacing={2} xs={12} md={6}>
        <Grid item xs={6}>
          <LwFormInput
            name="lastName"
            label="Acthernaam"
            control={control}
            rules={{ required: 'Selecteer een Acthernaam' }}
            disabled={!!defaultValues.lastName}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="firstName"
            label="Voornaam"
            control={control}
            rules={{ required: 'Selecteer een Voornaam' }}
            disabled={!!defaultValues.firstName}
          />
        </Grid>
        <Grid item xs={6} display="flex" alignItems="end">
          <LwFormSelect
            name="documentType"
            options={documentTypeOptions}
            label="Document Type"
            control={control}
            rules={{ required: 'Selecteer een document type' }}
          />
        </Grid>
        <Grid item xs={6} display="flex" alignItems="end">
          <LwFormInput
            name="documentNumber"
            label="Documentnummer"
            control={control}
            rules={{ required: 'Selecteer een documentnummer' }}
          />
        </Grid>

        <Grid item xs={6} display="flex" alignItems="end">
          <LwFormDate
            name="issuanceDate"
            label="Datum van uitgifte"
            control={control}
            rules={{ required: 'Selecteer een datum van uitgifte' }}
          />
        </Grid>
        <Grid item xs={6} display="flex" alignItems="end">
          <LwFormDate
            name="expirationDate"
            label="Vervaldatum"
            control={control}
            rules={{ required: 'Selecteer een vervaldatum' }}
          />
        </Grid>
        <Grid item xs={6} display="flex" alignItems="end">
          <LwFormDate
            name="dateOfBirth"
            label="Geboortedatum"
            control={control}
            rules={{ required: 'Selecteer een Geboortedatum' }}
          />
        </Grid>
        {isBSNFieldShown ? (
          <Grid item xs={4}>
            <Controller
              control={control}
              name="bsn"
              render={({ field: { onChange } }) => (
                <TextField
                  title="BSN"
                  value={workerIdentityInformation.bsn}
                  label="BSN"
                  variant="filled"
                  fullWidth
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <StyledButton
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            disabled={false}
          >
            <Typography variant="body2">Goedkeuren</Typography>
          </StyledButton>
          <StyledButton onClick={onDeny} variant="contained" size="large" color="primary">
            <Typography variant="body2">Afwijzen</Typography>
          </StyledButton>
        </Grid>
      </Grid>
    </form>
  );
};

const StyledButton = styled(Button)({
  marginRight: 12,
});
