import { Box, styled } from '@mui/material';
import { DateTime } from 'luxon';
import { DepartmentSelect } from 'ogp/components/views/planning/components';
import { CalendarDateControl } from './calendar-date-control/calendar-date-control';
import { CreateNewShiftButton } from '../components/create-new-shift-button/create-new-shift-button';
import { ExportShiftsButton } from '../components/export-shifts-button/export-shifts-button';

type CalendarPlanningHeadProps = {
  date: DateTime;
  openExportModal: () => void;
};

export const CalendarPlanningToolbox = ({ date, openExportModal }: CalendarPlanningHeadProps) => {
  return (
    <Header>
      <CreateNewShiftButton />
      <CalendarDateControl date={date} />
      <ButtonSection>
        <ExportShiftsButton openExportModel={openExportModal} />
        <DepartmentSelect />
      </ButtonSection>
    </Header>
  );
};

const Header = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  marginTop: theme.spacing(8),
  gap: theme.spacing(8),
  width: '100%',
  justifyContent: 'space-between',
}));

const ButtonSection = styled(Box)(({ theme }) => ({
  gap: theme.spacing(8),
  display: 'flex',
}));
