import { Box, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useVisibleDaysProvider } from '../../providers/visible-days-provider';
import { isToday } from '../../../../../../../../../shared/utils/date-utils';

export const PlanningGridHeader = () => {
  const { visibleDays } = useVisibleDaysProvider();
  const classes = getClasses({ dayCount: visibleDays.length });
  const weekDayAbreviation = (d: string) => DateTime.fromISO(d).toFormat('ccc');
  const monthNumber = (d: string) => DateTime.fromISO(d).toFormat('dd');

  return (
    <Box display="flex" alignItems="center" mt={8} className={classes.header}>
      <Grid className={classes.weekDays} container flexWrap={'nowrap'}>
        {visibleDays.map((columnDate) => (
          <Grid item className={classes.dayHead} key={columnDate}>
            <Box
              className={clsx(classes.lozenge, { [classes.today]: isToday(columnDate) })}
              margin="0 auto"
              alignItems={'center'}
            >
              <Typography variant="inherit" className={classes.weekDay} fontSize="12px">
                {weekDayAbreviation(columnDate)}
              </Typography>
              <Typography variant="inherit" className={classes.monthNumber} fontSize="16px">
                {monthNumber(columnDate)}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const getClasses = makeStyles((theme: Theme) => ({
  header: {
    gap: theme.spacing(8),
    '& .MuiGrid-item:nth-child(2n - 1)': {
      background: 'white',
      borderTopRightRadius: '1rem',
      borderTopLeftRadius: '1rem',
    },
  },
  weekDays: {
    marginTop: theme.spacing(2),
    paddingLeft: '14vw',
  },
  dayHead: {
    textTransform: 'capitalize',
    width: ({ dayCount }: { dayCount: number }) => `calc(100% / ${dayCount})`,
    padding: theme.spacing(2),
    '&:last-child': {
      borderRight: 'none',
    },
  },
  lozenge: {
    padding: '1rem 1.5rem',
    gap: '0.75rem',
    borderRadius: '4.125rem',
    width: 'fit-content',
    display: 'flex',
  },
  monthNumber: { fontWeight: 'bolder', lineHeight: 'normal', letterSpacing: '0.025rem' },
  weekDay: {
    textTransform: 'uppercase',
    opacity: 0.4,
  },
  today: {
    color: '#002BFF',
    background: 'rgba(0, 43, 255, 0.10)',
    '& > *': {
      opacity: 1,
    },
  },
}));
