import {
  usePlanningDateRange,
  useReadPlanningPathParams,
  useUpdatePlanningPath,
} from 'ogp/hooks/planning';

import { DateTime } from 'luxon';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlanningHeader } from './planning-header/planning-header';
import { PlanningModalProvider } from './planning-modal/planning-modal';
import { PlanningContent } from './planning-content/planning-content';

export const Planning = () => {
  const navigate = useNavigate();
  const { startDate } = usePlanningDateRange();
  const updatePlanningPath = useUpdatePlanningPath();
  const { date: paramsDate, shiftDetailId } = useReadPlanningPathParams();

  const closeShiftDetail = useCallback(() => {
    const path = updatePlanningPath({ shiftDetailId: null });
    navigate(path, { replace: true });
  }, [navigate, updatePlanningPath]);

  useEffect(() => {
    const navigateToValidPath = () => {
      const isDateValid = paramsDate?.startOf('week').isValid;
      if (!isDateValid) {
        const path = updatePlanningPath({ date: DateTime.now() });
        navigate(path, { replace: true });
      }
    };

    navigateToValidPath();
  }, [paramsDate, navigate, updatePlanningPath]);

  return (
    <>
      <PlanningHeader type={'calendar'} setView={() => {}} date={startDate} />
      <PlanningContent type={'calendar'} />
      <PlanningModalProvider onClose={closeShiftDetail} shiftDetailId={shiftDetailId} />
    </>
  );
};
