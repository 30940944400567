import type { Column } from 'react-table';
import { ContextMenuButton, TableCell, TableHeader } from 'redesign';
import { pluralizeWithValue } from 'shared/utils/pluralize';
import { ArchiveTableColumnDefinition } from 'ogp/components/views/administration/shifts-checkouts/archive/components/table/helpers/archive-table.types';
import { Box, Tooltip, Typography } from '@mui/material';
import { IconCertificate } from 'assets/img';
import { convertToExperienceFormat } from 'shared/components/flex-workers-for-work-table/utils/helpers';
import { ApplicantCellProps, ApplicantColumnDefinition } from './applicant-table.types';
import { getShiftDetailsColumnDefinitionBase } from '../../../shared/columns/get-shift-details-column-definition-base';
import { EmploymentType } from '../../../../../../../../types/common/employment-type.types';
import { HourlyRateCell } from '../hourly-rate-cell';

export const getApplicantColumnDefinition: <TAction extends object>(
  employmentType: EmploymentType,
  isProjectTable: boolean,
  options?: { showAppliedShifts?: boolean }
) => Column<ApplicantColumnDefinition<TAction>>[] = (
  employmentType,
  projectTable,
  { showAppliedShifts = true } = { showAppliedShifts: true }
) => {
  const [flexWorkerImage] = getShiftDetailsColumnDefinitionBase();

  const flexWorkerFullName: Column<ApplicantColumnDefinition> = {
    Header: () => <TableHeader>Flexwerker</TableHeader>,
    accessor: 'flexWorkerFullName',
    Cell: FlexWorkerCell,
  };

  const hasRequiredCertificates: Column<ApplicantColumnDefinition> = {
    Header: () => <TableHeader>Certificaat</TableHeader>,
    accessor: 'hasRequiredCertificates',
    Cell: CertificateChecksCell,
  };

  const rating: Column<ApplicantColumnDefinition> = {
    Header: () => <TableHeader>Beoordeling</TableHeader>,
    accessor: 'rating',
    Cell: RatingCell,
  };

  const attendanceRate: Column<ApplicantColumnDefinition> = {
    Header: () => <TableHeader>Opkomst</TableHeader>,
    accessor: 'attendanceRate',
    Cell: AttendanceRateCell,
  };

  const workedShifts: Column<ApplicantColumnDefinition> = {
    Header: () => <TableHeader>Gewerkt</TableHeader>,
    accessor: 'workedShifts',
    Cell: WorkedShiftsCell,
  };

  const hourlyRate: Column<ApplicantColumnDefinition> = {
    Header: () => <TableHeader>Tarief zzp'er</TableHeader>,
    accessor: 'hourlyRate',
    Cell: HourlyRateCell,
  };

  const appliedShifts: Column<ApplicantColumnDefinition> = {
    Header: () => <TableHeader>Aanmeldingen</TableHeader>,
    accessor: 'appliedShifts',
    Cell: AppliedShiftsCell,
  };

  const phone: Column<ApplicantColumnDefinition> = {
    Header: () => <TableHeader>Telefoonnummer</TableHeader>,
    accessor: 'phone',
    Cell: PhoneCell,
  };

  const experience: Column<ApplicantColumnDefinition> = {
    Header: () => <TableHeader>Werkervaring</TableHeader>,
    accessor: 'experienceInMonths',
    Cell: ExperienceCell,
  };

  const distance: Column<ApplicantColumnDefinition> = {
    Header: () => <TableHeader>Afstand</TableHeader>,
    accessor: 'distanceInKm',
    Cell: DistanceCell,
  };

  const actions: Column<ArchiveTableColumnDefinition> = {
    accessor: 'actions',
    Header: '',
    Cell: ActionCell,
  };

  return [
    flexWorkerImage,
    flexWorkerFullName,
    hasRequiredCertificates,
    rating,
    attendanceRate,
    workedShifts,
    hourlyRate,
    ...(showAppliedShifts ? [appliedShifts] : []),
    phone,
    experience,
    distance,
    actions,
  ];
};

const FlexWorkerCell = ({ value }: ApplicantCellProps<'flexWorkerFullName'>) => {
  return (
    <TableCell style={{ maxWidth: '90px' }}>
      <Tooltip title={value}>
        <Typography noWrap>{value}</Typography>
      </Tooltip>
    </TableCell>
  );
};

const RatingCell = ({ value }: ApplicantCellProps<'rating'>) => {
  return value ? <Typography noWrap>{value}%</Typography> : null;
};

const CertificateChecksCell = ({ value }: ApplicantCellProps<'hasRequiredCertificates'>) => {
  return value ? (
    <Tooltip
      title="Deze flexwerker is in het bezit van alle benodigde certificaten"
      aria-label="certificate-check"
    >
      <Box paddingLeft={5}>
        <IconCertificate width={24} />
      </Box>
    </Tooltip>
  ) : null;
};

const AttendanceRateCell = ({ value }: ApplicantCellProps<'attendanceRate'>) => {
  return value ? <TableCell>{value}%</TableCell> : null;
};

const WorkedShiftsCell = ({ value }: ApplicantCellProps<'workedShifts'>) => {
  return <TableCell>{pluralizeWithValue(value, 'shift', 'shifts', '0')}</TableCell>;
};

const AppliedShiftsCell = ({ value }: ApplicantCellProps<'appliedShifts'>) => {
  return <TableCell>{value}</TableCell>;
};

const PhoneCell = ({ value }: ApplicantCellProps<'appliedShifts'>) => {
  return <TableCell>{value}</TableCell>;
};

const ExperienceCell = ({ value }: ApplicantCellProps<'appliedShifts'>) => {
  return <TableCell>{convertToExperienceFormat(value)}</TableCell>;
};

const DistanceCell = ({ value }: ApplicantCellProps<'appliedShifts'>) => {
  if (value === undefined) {
    return <TableCell>-</TableCell>;
  }
  const roundedValue = Math.round((value + Number.EPSILON) * 10) / 10;
  return <TableCell>{roundedValue} km</TableCell>;
};

const ActionCell = ({ value, getRowActions }: ApplicantCellProps<'actions'>) => {
  const actions = getRowActions(value);

  if (actions.length === 0) {
    return null;
  }

  return (
    <ContextMenuButton menuId={`claimant-actions-menu-${value.workerId}`}>
      {actions}
    </ContextMenuButton>
  );
};
