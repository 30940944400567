import { DateTime } from 'luxon';
import { AutocompleteOption } from 'redesign';
import { getJobTypes } from 'partner/components/views/partner-placements/filters/utils/utils';
import { PlacementsFiltersData, PlacementsFiltersFormType } from './placements-filters.types';
import { PlacementsFilters } from './hooks/usePlacementsPage.types';

export const getPlacementsFiltersDefaultValues = (
  appliedFilters: PlacementsFilters,
  filterData: PlacementsFiltersData,
  setFlexWorkerSelectOption: (value: AutocompleteOption | null) => void
): PlacementsFiltersFormType => {
  const fromDate = appliedFilters.fromDate
    ? appliedFilters.fromDate.toFormat('yyyy-MM-dd')
    : DateTime.now().toFormat('yyyy-MM-dd');
  const isFromDateCheckboxSelected = !!appliedFilters.fromDate;

  const businessUnitCheckboxValue = !!appliedFilters.company;
  const result: PlacementsFiltersFormType = {
    fromDateCheckboxValue: isFromDateCheckboxSelected,
    fromDate,
    businessUnitCheckboxValue,
    company: null,
    branchOffice: null,
    department: null,
    jobTypeCheckboxValue: !!appliedFilters.jobType?.length,
    jobType: null,
    allowWorkersFilter: !!appliedFilters.worker,
    worker: null,
    employmentTypeCheckboxValue: (appliedFilters.isFreelance || appliedFilters.isTempWork) ?? false,
    isFreelance: appliedFilters.isFreelance ?? false,
    isTempWork: appliedFilters.isTempWork ?? false,
    state: appliedFilters.states ?? [],
    isStateFilterEnabled: !!appliedFilters.states,
    accepted: appliedFilters.states?.includes('accepted') ?? false,
    cancelled: appliedFilters.states?.includes('canceled') ?? false,
    cancelationOfferClaim: appliedFilters.states?.includes('cancelationOfferClaim') ?? false,
    cancelationOfferPending: appliedFilters.states?.includes('cancelationOfferPending') ?? false,
    counterOffer: appliedFilters.states?.includes('counterOffer') ?? false,
    noShow: appliedFilters.states?.includes('noShow') ?? false,
    checkoutPending: appliedFilters.states?.includes('checkoutPending') ?? false,
    checkoutSettled: appliedFilters.states?.includes('checkoutSettled') ?? false,
  };

  if (appliedFilters.jobType?.length) {
    const jobTypes = getJobTypes(appliedFilters, filterData.jobTypes);
    if (jobTypes?.length) {
      result.jobType = jobTypes.map((i) => ({ id: i.value, name: i.label }));
    }
  }

  if (!appliedFilters.company) {
    return result;
  }
  if (!filterData?.companies) {
    return result;
  }
  const companyFilter = filterData.companies.find((c) => c.companyId === appliedFilters.company);

  if (!companyFilter) {
    return result;
  }

  const company: AutocompleteOption = {
    value: companyFilter.companyId,
    label: companyFilter.companyName,
  };
  result.company = company;

  if (!appliedFilters.branchOffice) {
    return result;
  }

  const branchOfficeFilter = companyFilter.branchOffices.find(
    (bo) => bo.branchOfficeId === appliedFilters.branchOffice
  );

  if (!branchOfficeFilter) {
    return result;
  }

  const branchOffice: AutocompleteOption = {
    value: branchOfficeFilter.branchOfficeId,
    label: branchOfficeFilter.branchOfficeName,
  };
  result.branchOffice = branchOffice;

  if (!appliedFilters.department) {
    return result;
  }

  const departmentFilter = branchOfficeFilter.departments.find(
    (dept) => dept.departmentId === appliedFilters.department
  );

  if (!departmentFilter) {
    return result;
  }

  const department = {
    value: departmentFilter.departmentId,
    label: departmentFilter.departmentName,
  };

  result.department = department;

  return result;
};
