import { LocationAutoSuggestItem, LocationData } from '@types';
import { breakOptionsSelectInputMapper } from 'shared/components/shift-forms/components/forms/shared/inputs/break-minutes-input';
import { BulkShiftForm } from './bulk-shift-edit-form';
import { RenderUnsettledUI, SmallError, SmallLoader } from '../../../../../../../shared/components';
import { useGetBreakOptions } from '../../../../../../hooks/queryHooks/break-options/use-get-break-options';

export type BulkShiftEditForm = {
  startTime: string | null;
  endTime: string | null;
  breakMinutes: number | null;
  maxClaimants: number | null;
  location: LocationAutoSuggestItem | LocationData | null;
};

export const BulkShiftEditFormProvider = () => {
  const breakOptionsQuery = useGetBreakOptions();

  if (breakOptionsQuery.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={breakOptionsQuery}
      />
    );
  }

  const breakMinutesOptions = breakOptionsSelectInputMapper(breakOptionsQuery.data.breakOptions);

  return <BulkShiftForm breakOptions={breakMinutesOptions} />;
};
