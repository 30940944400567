import { Navigate, Route, Routes } from 'react-router-dom';
import { merge } from 'lodash';
import { BaseLayout } from 'hq/layouts/base-layout';
import { Companies } from 'hq/components/views/companies/companies';
import { CompanyDetailDataProvider } from 'hq/components/views/companies/components/company-detail/company-detail';
import { FlexWorkerEdit } from 'hq/components/views/flexWorkers/flex-worker-detail/forms/flexworker-edit';
import { BranchOfficeCreate } from 'hq/components/views/companies/components/company-detail/company-detail-tabs/tabs/company-branch-offices/components/forms/branch-office-create';
import { BranchOfficeEdit } from 'hq/components/views/companies/components/company-detail/company-detail-tabs/tabs/company-branch-offices/components/forms/branch-office-edit';
import { HqUsers } from 'hq/components/views/users/hq-users';
import { UserDetail } from 'hq/components/views/users/detail/user-detail';
import { UserEdit } from 'hq/components/views/users/forms/user-edit';
import { UserCreate } from 'hq/components/views/users/forms/user-create';
import { RouteRenderer } from 'router';
import { RouteDefinition } from 'Routes';
import { NotFound } from 'shared/components/views/404/404';
import { Unauthorized } from 'shared/components/views/unauthorized/Unauthorized';
import { useGetUserInfo } from 'hq/hooks/queryHooks/users/useGetUserInfo';
import { StateError, StateLoading } from 'shared/components/common/state/State';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { UploadPayments } from 'hq/components/views/upload-payments/upload-payments';
import { DialogProvider } from 'ogp/providers/dialog-provider';
import { HqShiftCreateDataProvider } from 'shared/components/shift-forms/hq/hq-shift-create/hq-shift-create';
import { HqShiftDuplicateDataProvider } from 'shared/components/shift-forms/hq/hq-shift-duplicate.tsx/hq-shift-duplicate';
import { HqShiftMultiplyDataProvider } from 'shared/components/shift-forms/hq/hq-shift-multiply/hq-shift-multiply';
import { HqShiftEditDataProvider } from 'shared/components/shift-forms/hq/hq-shift-edit/hq-shift-edit';
import { hqRoutes, RouteKeys } from './Routes';
import { Finance } from './components/views/finance/finance';
import { FlexPoolsAddFlexWorker } from './components/views/flexWorkers/flex-worker-detail/tabs/flex-pools/forms/flex-pools-add-flex-worker';
import { CompanyEdit } from './components/views/companies/components/forms/company-edit';
import { CompanyCreate } from './components/views/companies/components/forms/company-create';
import { FlexWorkers } from './components/views/flexWorkers/flex-workers';
import { Accreditations } from './components/views/accreditations/accreditations';
import { FeatureFlagPage } from './components/views/feature-flags/feature-flags';
import { Partners } from './components/views/partners/partners';
import { PartnerCreate } from './components/views/partners/partner-create';
import { ShiftPlanning } from './components/views/planner/shifts/shift-planning-page';
import { PartnerUsersList } from './components/views/partner-detail/partner-user/partner-users';
import { PartnerUserCreate } from './components/views/partner-detail/partner-user/partner-user-create';
import { PartnerRelationCreateForm } from './components/views/partner-detail/partner-relations/partner-relation-create-form';
import { PartnerRelationEditForm } from './components/views/partner-detail/partner-relations/partner-relation-edit-form';
import { PartnerUserEdit } from './components/views/partner-detail/partner-user/partner-user-edit';
import { PartnerInfo } from './components/views/partner-detail/partner-information/partner-info';
import { PartnerRelations } from './components/views/partner-detail/partner-relations/partner-relations';
import { PartnerInfoEdit } from './components/views/partner-detail/partner-information/partner-information-edit';
import { ProjectPlanningPage } from './components/views/planner/projects/project-planning-page';
import { ProjectEditDataProvider } from './components/views/planner/projects/forms/edit/project-edit-data-provider';
import { ProjectDuplicateDataProvider } from './components/views/planner/projects/forms/duplicate/project-duplicate-data-provider';
import { BankAccount } from './components/views/bank-account/bank-account';
import { AddAccreditationFormHqDataProvider } from './components/views/accreditations/form/add-accreditation-hq-form-data-provider';
import { PlatformSettingsPage } from './components/views/platform-settings/platform-settings-page';
import { UpdatePlatformSettingsPage } from './components/views/platform-settings/update-plaftorm-settings-page';
import { CompanyGrossHourlyRateCreate } from './components/views/companies/components/company-detail/company-detail-tabs/tabs/company-gross-hourly-rate/components/create-company-gross-hourly-rate/create-company-gross-hourly-rate';
import { EditAccreditationHqFormDataProvider } from './components/views/accreditations/form/edit-accreditation-hq-form-data-provider';
import { ProjectCreateDataProvider } from './components/views/planner/projects/forms/create/project-create-data-provider';
import { HQPlacements } from './components/views/planner/placements/placements';
// Components defined separately per route in order to prevent circular dependencies
const components: Record<RouteKeys, Partial<RouteDefinition>> = {
  Unauthorized: { element: <Unauthorized /> },
  Companies: { element: <Companies /> },
  CompaniesEdit: { element: <CompanyEdit /> },
  CompaniesCreate: { element: <CompanyCreate /> },
  CompanyDetail: { element: <CompanyDetailDataProvider /> },
  CompanyGrossHourlyRateCreate: { element: <CompanyGrossHourlyRateCreate /> },
  FlexWorkers: { element: <FlexWorkers /> },
  BankAccount: { element: <BankAccount /> },
  FlexWorkersEdit: { element: <FlexWorkerEdit /> },
  FlexPoolsAddFlexWorker: { element: <FlexPoolsAddFlexWorker /> },
  BranchOfficeCreate: { element: <BranchOfficeCreate /> },
  BranchOfficeEdit: { element: <BranchOfficeEdit /> },
  Finance: { element: <Finance /> },
  HQUsers: { element: <HqUsers /> },
  HQUsersEdit: { element: <UserEdit /> },
  HQUsersCreate: { element: <UserCreate /> },
  HQUsersDetail: { element: <UserDetail /> },
  UploadPayments: { element: <UploadPayments /> },
  FeatureFlags: { element: <FeatureFlagPage /> },
  PlatformSettings: { element: <PlatformSettingsPage /> },
  PlatformSettingsUpdate: { element: <UpdatePlatformSettingsPage /> },
  Partners: { element: <Partners /> },
  PartnerRelation: {
    element: <PartnerRelations />,
  },
  PartnerRelationCreate: {
    element: <PartnerRelationCreateForm />,
  },
  PartnerRelationEdit: {
    element: <PartnerRelationEditForm />,
  },
  Accreditations: { element: <Accreditations /> },
  AccreditationsCreate: { element: <AddAccreditationFormHqDataProvider /> },
  AccreditationsEdit: { element: <EditAccreditationHqFormDataProvider /> },
  PartnerCreate: { element: <PartnerCreate /> },
  PartnerDetail: { element: <PartnerInfo /> },
  PartnerDetailEdit: { element: <PartnerInfoEdit /> },
  PartnerUsers: { element: <PartnerUsersList /> },
  PartnerUserCreate: { element: <PartnerUserCreate /> },
  PartnerUserEdit: { element: <PartnerUserEdit /> },
  PlannerShift: { element: <ShiftPlanning /> },
  PlannerShiftCreateShift: { element: <HqShiftCreateDataProvider /> },
  PlannerShiftDuplicateShift: { element: <HqShiftDuplicateDataProvider /> },
  PlannerShiftMultiplyShift: { element: <HqShiftMultiplyDataProvider /> },
  PlannerShiftEditShift: { element: <HqShiftEditDataProvider /> },
  PlannerProject: { element: <ProjectPlanningPage /> },
  PlannerProjectCreate: { element: <ProjectCreateDataProvider /> },
  ProjectsDuplicate: { element: <ProjectDuplicateDataProvider /> },
  ProjectsEdit: { element: <ProjectEditDataProvider /> },
  Placements: { element: <HQPlacements /> },
};

// TODO stop merging this as a side effect
const items = merge(hqRoutes, components) as Record<string, RouteDefinition>;

const Router = () => {
  const generatePath = useHqRelativePath();
  const userQuery = useGetUserInfo();

  switch (userQuery.status) {
    case 'loading':
      return <StateLoading />;
    case 'error':
      return <StateError error={userQuery.error} />;
    case 'success':
    default:
      if (!userQuery.data) {
        // TODO: should we redirect to hq
        return <StateError error={new Error('User not found')} />;
      }

      return (
        <DialogProvider>
          <Routes>
            <Route path="/" element={<Navigate replace to={generatePath(items.Companies)} />} />
            {RouteRenderer(Object.values(items), '', userQuery.data, BaseLayout)}

            <Route path="sales" element={<Navigate replace to={generatePath(items.Companies)} />} />
            <Route
              path="operations"
              element={<Navigate replace to={generatePath(items.FlexWorkers)} />}
            />
            <Route path="finance" element={<Navigate replace to={generatePath(items.Finance)} />} />
            <Route
              path="settings"
              element={<Navigate replace to={generatePath(items.HQUsers)} />}
            />
            <Route
              path="admin-settings"
              element={<Navigate replace to={generatePath(items.FeatureFlags)} />}
            />

            <Route element={<NotFound />} />
          </Routes>
        </DialogProvider>
      );
  }
};

export { Router };
