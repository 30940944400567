import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { LwFormDate } from 'redesign';
import { compareDateStrings } from 'shared/utils/validate-date';
import { Grid } from '@mui/material';
import { TableDateForm } from './table-date-control';

export const DateInputs = () => {
  const { watch, trigger } = useFormContext<TableDateForm>();

  const minDate = watch('startDate');
  const endDate = watch('endDate');

  useEffect(() => {
    if (minDate && endDate) {
      trigger('endDate');
    }
  }, [trigger]);

  return (
    <Grid spacing={4} container>
      <Grid item xs={6}>
        <StartDateElement />
      </Grid>
      <Grid item xs={6}>
        <EndDateElement minDate={minDate} />
      </Grid>
    </Grid>
  );
};

const StartDateElement = () => {
  const { control } = useFormContext<TableDateForm>();

  return (
    <LwFormDate
      name="startDate"
      label="Startdatum"
      control={control}
      rules={{
        required: 'Voer een startdatum in',
      }}
      data-testid="start-date"
    />
  );
};

const EndDateElement = ({ minDate }: { minDate: string }) => {
  const { control } = useFormContext<TableDateForm>();
  return (
    <LwFormDate
      name="endDate"
      label="Einddatum"
      control={control}
      rules={{
        validate: (value) => {
          if (!compareDateStrings(minDate, value?.toString() || '')) {
            return 'Einddatum moet na de startdatum liggen';
          }
        },
      }}
      minDate={minDate}
      data-testid="end-date"
    />
  );
};
