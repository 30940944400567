import { BreakOptions } from './break-options-service.types';
import { HqApiService } from './hqApiService';

export class BreakOptionsService {
  constructor(private readonly hqApiService: HqApiService) {}

  public async getBreakOptions() {
    const response = await this.hqApiService.get<BreakOptions>({
      path: 'break-options',
    });

    return response;
  }
}
