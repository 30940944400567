import {
  GetShiftPlanningShiftsResponse,
  ShiftPlanningBusinessUnitsData,
  ShiftPlanningJobTypesData,
  ShiftPlanningParams,
  ShiftPlanningPartnerUnitsData,
  ShiftPlanningShiftsPartner,
} from './shift-planning-service.types';
import { HqApiService } from './hqApiService';
import { BulkShiftActionDataResponse } from './shift-service.types';

class ShiftPlanningService {
  constructor(private readonly hqApiService: HqApiService) {}

  public async getShiftPlanningShifts({
    fromDate,
    company,
    branchOffice,
    department,
    pageParam,
    pageSizeParam,
    jobType,
    filledStatus,
    hasApplicants,
    partner,
    isFreelance,
    isTempWork,
  }: ShiftPlanningParams) {
    const response = await this.hqApiService.get<GetShiftPlanningShiftsResponse>({
      params: {
        page: pageParam,
        pageSize: pageSizeParam,
        fromDate: fromDate?.toISO(),
        companyId: company,
        branchOfficeId: branchOffice,
        departmentId: department,
        jobType: jobType,
        filledStatus,
        hasApplicants,
        partnerId: partner,
        isFreelance,
        isTempWork,
      },
      path: 'shift-planning/shifts',
    });

    return response;
  }

  public async getShiftPlanningBusinessUnitsData() {
    const response = await this.hqApiService.get<ShiftPlanningBusinessUnitsData>({
      path: 'shift-planning/business-units',
    });

    return response;
  }

  public async getShiftPlanningJobTypesData() {
    const response = await this.hqApiService.get<ShiftPlanningJobTypesData>({
      path: 'shift-planning/job-types',
    });
    return response;
  }

  public async getShiftPlanningPartnersData() {
    const response = await this.hqApiService.get<ShiftPlanningPartnerUnitsData>({
      path: 'shift-planning/partners',
    });

    return response;
  }

  public async getShiftsPartners(shiftIds: string[]): Promise<ShiftPlanningShiftsPartner[]> {
    const response = await this.hqApiService.create<ShiftPlanningShiftsPartner[]>({
      path: 'shift-planning/get-shifts-partners',
      body: {
        shiftIds,
      },
    });

    return response;
  }

  public async bulkAssignPartnersToShifts(shiftIds: string[], partnerIds: string[]) {
    const response = await this.hqApiService.update<BulkShiftActionDataResponse>({
      path: 'shift-planning/bulk-assign-partners-to-shifts',
      body: {
        shiftIds,
        partnerIds,
      },
    });
    return response;
  }
}

export { ShiftPlanningService };
