import { Box, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FlexPoolOnlyIndicator } from 'shared/components/common/flexpool-only-indicator';
import { getHelperTexts } from 'shared/components/shift-forms/components/forms/shared/get-helper-texts/get-helper-texts';
import { useGetJob } from 'shared/hooks/query-hooks/use-get-job';
import { getMinDate } from 'shared/utils/get-min-date';
import { CompanyEmploymentTypes, InputSelectOption } from '@types';
import { FormMode } from 'shared/components/shift-forms/components/forms/shared/types';
import { useEffect, useMemo } from 'react';
import { FormCheckbox } from 'redesign';
import { DepartmentFormElement } from './elements/department-form-element';
import { EndDateElement } from './elements/end-date-element';
import { HourlyRateElement } from './elements/hourly-rate-element';
import { JobFormElement } from './elements/job-form-element';
import { StartDateElement } from './elements/start-date-element';
import { WeeklyHoursElement } from './elements/weekly-hours-element';
import { ProjectFormTypeAll } from '../types/project-form.types';

export type ProjectFormInputsProps = {
  jobsData?: { id: string; name: string; flexPoolOnly: boolean }[];
  departments: InputSelectOption[];
  mode: FormMode;
  isProjectWithActivePlacement: boolean;
  companyEmploymentType: CompanyEmploymentTypes | undefined;
};

export const ProjectFormInputs = ({
  isProjectWithActivePlacement,
  departments,
  jobsData,
  mode,
  companyEmploymentType,
}: ProjectFormInputsProps) => {
  const { control, watch, setValue } = useFormContext<ProjectFormTypeAll>();

  const watchedDepartment = watch('departmentId');
  const watchedJobId = watch('jobId');
  const isFreelanceCheckboxChecked = watch('isFreelance');

  const { data: jobData, isLoading: isSelectedJobLoading } = useGetJob(watchedJobId);
  const { minDate } = getMinDate({ allowDateBeforeToday: true });
  const helperTexts = getHelperTexts(jobData);

  useEffect(() => {
    if (!jobData) {
      return;
    }
    setValue('isFreelance', !!jobData?.freelanceJob);
    setValue('isTempwork', !!jobData?.tempWorkJob);
  }, [jobData]);

  const jobOptionsForCreateForm = useMemo(() => {
    if (!jobsData) {
      return [];
    }
    return jobsData.map((job) => ({
      value: job.id,
      label: (
        <FlexPoolOnlyIndicator flexPoolOnly={job.flexPoolOnly}>{job.name}</FlexPoolOnlyIndicator>
      ),
    }));
  }, [jobsData]);

  const areCheckboxesDisabled = useMemo(() => {
    if (mode === 'update') {
      return true;
    }

    const companyAllowsBoth =
      companyEmploymentType && Object.values(companyEmploymentType).every(Boolean);

    if (!jobData) {
      return !companyAllowsBoth;
    }

    const jobEmploymentType = {
      isFreelance: !!jobData.freelanceJob,
      isTempwork: !!jobData.tempWorkJob,
    };

    const jobAllowsBoth = jobEmploymentType && Object.values(jobEmploymentType).every(Boolean);
    const isEnabled = watchedJobId && companyAllowsBoth && jobAllowsBoth;

    return !isEnabled;
  }, [companyEmploymentType, jobData, watchedJobId]);

  useEffect(() => {
    if (jobData && companyEmploymentType) {
      const { offersFreelanceWork, offersTempWork } = companyEmploymentType;

      setValue('isFreelance', !!jobData?.freelanceJob && offersFreelanceWork);
      setValue('isTempwork', !!jobData?.tempWorkJob && offersTempWork);
    }
  }, [jobData, companyEmploymentType]);

  return (
    <Box display="flex" flexDirection="row" height="100%">
      <form aria-label="Projects form">
        <Grid container item spacing={4} xs={12}>
          <Grid item xs={6}>
            <DepartmentFormElement
              key="departmentId"
              control={control}
              disabled={mode !== 'create'}
              options={departments}
              onChange={() => {
                setValue('jobId', '');
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {mode === 'create' ? (
              <JobFormElement
                key="jobId"
                control={control}
                disabled={mode !== 'create' || !watchedDepartment}
                options={jobOptionsForCreateForm}
              />
            ) : null}
          </Grid>
          <Grid item xs={6}>
            <StartDateElement
              key="startDate"
              control={control}
              disabled={isProjectWithActivePlacement}
              minDate={minDate}
            />
          </Grid>
          <Grid item xs={6}>
            <EndDateElement
              key="endDate"
              control={control}
              disabled={isProjectWithActivePlacement}
              minDate={minDate}
            />
          </Grid>
          <Grid item xs={6}>
            <WeeklyHoursElement
              key="weeklyHours"
              control={control}
              disabled={isSelectedJobLoading}
              existingHours={undefined}
            />
          </Grid>

          <Grid item xs={12}>
            <FormCheckbox
              name="isTempwork"
              label="Uitzendkracht"
              control={control}
              disabled={areCheckboxesDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <FormCheckbox
              name="isFreelance"
              label="Zelfstandige"
              control={control}
              disabled={areCheckboxesDisabled}
            />
          </Grid>

          <Grid item xs={6}>
            <HourlyRateElement
              key="hourlyRate"
              control={control}
              disabled={
                !isFreelanceCheckboxChecked || isSelectedJobLoading || isProjectWithActivePlacement
              }
              required={isFreelanceCheckboxChecked}
              existingRate={jobData?.freelanceJob?.hourlyRateCents}
              helperTexts={helperTexts}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
