import { Grid } from '@mui/material';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { hqRoutes } from 'hq/Routes';
import { GetCompaniesResponse } from 'hq/services/company-service.types';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Header, Page } from 'redesign';
import { useAlert } from 'shared/components/alerts';
import { ToolboxFormCreate } from 'shared/components/common/toolbox-form-create';
import { HQProjectFormTypeCreate } from 'shared/components/project-forms/types/project-form.types';
import { CompanyInput } from 'shared/components/shift-forms/components/forms/shared/inputs/company-input';
import { stringifyFilters } from 'shared/utils/stringify-filters';
import { HQProjectPayloadCreate } from 'hq/services/project-planning-service.types';
import { useGetJobDetails } from 'shared/components/work-forms/details/hooks/use-get-job-details';
import { useGetDepartmentAsDetail } from 'shared/components/shift-forms/components/forms/shift-form/hooks/use-get-department-as-detail';
import { RenderUnsettledUI } from 'shared/components';
import { ProjectCreateFormProvider } from './project-create-form-provider';
import { useCreateProject } from '../../hooks/use-create-project';
import { useProjectPlanningPage } from '../../table/hooks/use-project-planning';

type ProjectCreateOrDuplicateProps = {
  companies: GetCompaniesResponse[];
  successMessage: string;
  pageTitle: string;
};

export const ProjectCreateFormPage = ({
  companies,
  successMessage,
  pageTitle,
}: ProjectCreateOrDuplicateProps) => {
  const { alertSuccess, alertError } = useAlert();

  const formMethods = useForm<HQProjectFormTypeCreate>({});
  const jobId = formMethods.watch('jobId');
  const jobDetailsQuery = useGetJobDetails(jobId);

  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const mutation = useCreateProject();
  const { params: filters } = useProjectPlanningPage();

  const companyId = formMethods.watch('companyId');
  const departmentId = formMethods.watch('departmentId');
  const departmentQuery = useGetDepartmentAsDetail(departmentId, departmentId !== undefined);

  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit((data: HQProjectFormTypeCreate) => {
      const payload: HQProjectPayloadCreate = {
        jobId: data.jobId,
        startDate: data.startDate,
        endDate: data.endDate,
        weekHourVolume: +data.weeklyHours,
        freelanceProperties: data.isFreelance
          ? {
              hourlyRateCents: Math.round(+data.hourlyRate * 100),
            }
          : undefined,
        isTempWork: data.isTempwork,
      };
      mutation.mutate(payload, {
        onSuccess: (id) => {
          alertSuccess(successMessage);
          navigate(
            generatePath(
              hqRoutes.PlannerProject,
              {},
              { ...stringifyFilters(filters), projectDetailId: id }
            )
          );
        },
        onError: (e) => alertError(e),
      });
    })();
  }, []);

  const onCancel = useCallback(
    () => navigate(generatePath(hqRoutes.PlannerProject, stringifyFilters(filters))),
    [filters, generatePath, navigate]
  );

  useEffect(() => {
    formMethods.reset({ companyId });
  }, [companyId]);

  if (departmentQuery.status === 'error') {
    return <RenderUnsettledUI data={departmentQuery} />;
  }

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={pageTitle} />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={onCancel}
          />
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <CompanyInput
              options={companies.map((i) => ({ label: i.name, value: i.id }))}
              disabled={false}
            ></CompanyInput>
            {companyId ? (
              <ProjectCreateFormProvider
                companyId={companyId}
                jobsData={departmentQuery.data?.jobs ?? []}
                workDetails={jobDetailsQuery.data}
              />
            ) : null}
          </Grid>
        </Grid>
      </Page>
    </FormProvider>
  );
};
