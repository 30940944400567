import { useToggle } from 'shared/hooks';

import { LWModal, Table, useOnScrollDown } from 'redesign';
import { DEFAULT_PAGE_SIZE } from 'shared/utils/constants';
import { RenderUnsettledUI } from 'shared/components';
import { FeatureFlagProvider } from 'shared/contexts/feature-flag-provider';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useGetShiftPlanningShifts } from './hooks/use-get-shift-planning-shifts';
import { getShiftPlanningTableData } from './utils/get-shift-planning-table-data';
import { getSelectedRows } from './utils/get-selected-row-ids';
import {
  HqShiftPlanningColumnDefinition,
  HqShiftPlanningTableOptions,
  INITIAL_PAGE,
  ShiftPlanningTableProps,
} from './shift-planning-table.types';
import { getShiftPlanningColumnDefinition } from './utils/get-shift-planning-column-definition';
import { useShiftPlanningPage } from './hooks/use-shift-planning';
import { ShiftPlanningDataProvider } from '../filters/shift-planning-filters-data-provider';
import { ShiftPlanningModalProvider } from '../shift-planning-modal/shift-planning-modal';

type ShiftPlanningTableDataProviderProps = {
  onSelectionChange: (selectedIds: string[]) => void;
  selectedShiftIds: string[];
};

export const ShiftPlanningTableDataProvider: FC<ShiftPlanningTableDataProviderProps> = ({
  onSelectionChange,
  selectedShiftIds,
}) => {
  const { filters: appliedFilters } = useShiftPlanningPage();

  const shiftsQuery = useGetShiftPlanningShifts({
    fromDate: appliedFilters.fromDate ?? undefined,
    company: appliedFilters.company ?? undefined,
    branchOffice: appliedFilters.branchOffice ?? undefined,
    department: appliedFilters.department ?? undefined,
    jobType: appliedFilters.jobType ?? undefined,
    hasApplicants: appliedFilters.hasApplicants ?? undefined,
    filledStatus: appliedFilters.filledStatus ?? undefined,
    partner: appliedFilters.partner ?? undefined,
    isFreelance: appliedFilters.isFreelance ?? undefined,
    isTempWork: appliedFilters.isTempWork ?? undefined,
    pageParam: INITIAL_PAGE,
    pageSizeParam: DEFAULT_PAGE_SIZE,
  });

  if (shiftsQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftsQuery} />;
  }

  return (
    <ShiftPlanningTable
      shiftsQuery={shiftsQuery}
      selectedShiftIds={selectedShiftIds}
      onSelectionChange={onSelectionChange}
    />
  );
};

const ShiftPlanningTable = ({
  shiftsQuery,
  onSelectionChange,
  selectedShiftIds,
}: ShiftPlanningTableProps) => {
  const { data } = shiftsQuery;
  const { filters: appliedFilters, setFilters } = useShiftPlanningPage();
  const flatData = useMemo(() => data.pages.flatMap((page) => page.items), [data]);
  const tableData = useMemo(() => getShiftPlanningTableData(flatData), [flatData]);
  const columns = useMemo(() => getShiftPlanningColumnDefinition(), []);
  const { fetchIfBottomScrolled } = useOnScrollDown({
    infiniteQueryResult: shiftsQuery,
    flatData: flatData,
  });
  const { on: filtersOpen, toggle: toggleFilters } = useToggle();
  const [searchParams] = useSearchParams();
  const defaultShiftDetailId = searchParams.get('shiftDetailId');
  const [shiftId, setShiftId] = useState<string | undefined>(defaultShiftDetailId || undefined);
  const selectedRows = getSelectedRows(flatData, selectedShiftIds);

  const handleSelectionChange = useCallback(
    (rows: HqShiftPlanningColumnDefinition[]) => {
      const selectedIds = rows.map((row) => row.id);
      onSelectionChange(selectedIds);
    },
    [onSelectionChange]
  );

  const openShiftDetail = useCallback(
    (shiftId: string) => {
      setFilters({ shiftDetailId: shiftId });
      setShiftId(shiftId);
    },
    [setFilters]
  );

  const closeShiftDetail = useCallback(() => {
    setFilters({ shiftDetailId: undefined });
    setShiftId(undefined);
  }, [setFilters]);

  if (shiftsQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftsQuery} />;
  }

  return (
    <>
      <Box minHeight={0}>
        <Table<HqShiftPlanningColumnDefinition, HqShiftPlanningTableOptions>
          stickyHeader={true}
          columns={columns}
          data={tableData}
          tableStyle={{ tableLayout: 'fixed' }}
          onScroll={fetchIfBottomScrolled}
          onShowDetail={openShiftDetail}
          data-testid="shift-planning-virtual-table"
          onSelectionChange={handleSelectionChange}
          selectable={true}
          selectedRows={selectedRows}
        />
      </Box>
      <FeatureFlagProvider>
        <ShiftPlanningDataProvider
          appliedFilters={appliedFilters}
          handleFiltersChange={setFilters}
          isOpen={filtersOpen}
          toggleFilters={toggleFilters}
        />
      </FeatureFlagProvider>

      <LWModal isOpen={shiftId != null} onClose={closeShiftDetail} testId="shift-detail">
        {shiftId ? (
          <ShiftPlanningModalProvider
            shiftDetailId={shiftId}
            onDelete={shiftsQuery.refetch}
            closeShiftDetail={closeShiftDetail}
          />
        ) : null}
      </LWModal>
    </>
  );
};
