import { useInfiniteQuery } from 'react-query';
import { GetPlacementParams } from './get-placement-param.types';
import { PlacementsFilters } from './usePlacementsPage.types';
import { QUERY_KEYS_HQ } from '../../../../../../query-keys';
import { placementService } from '../../../../../../services';
import { DEFAULT_PAGE_SIZE } from '../../../../../../../shared/utils/constants';
const INITIAL_PAGE = 0;

const useGetPlacements = ({ fromDate, ...filters }: PlacementsFilters) => {
  const params: GetPlacementParams = {
    fromDate: fromDate ? fromDate.toISO() : undefined,
    companyId: filters.company,
    branchOfficeId: filters.branchOffice,
    departmentId: filters.department,
    jobTypeIds: filters.jobType,
    workerId: filters.worker,
    isFreelance: filters.isFreelance,
    isTempWork: filters.isTempWork,
    states: filters.states,
    page: INITIAL_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  };

  return useInfiniteQuery({
    queryKey: QUERY_KEYS_HQ.placementsWithFilter(params),
    queryFn: async () => await placementService.getAllPlacements(params),
  });
};

export { useGetPlacements };
