import { useQuery } from 'react-query';
import { BreakOptions } from 'hq/services/break-options-service.types';
import { QUERY_KEYS_HQ } from '../../../query-keys';
import { breakOptionsService } from '../../../services';

export const useGetBreakOptions = () => {
  return useQuery<BreakOptions>({
    queryKey: QUERY_KEYS_HQ.breakOptions(),
    queryFn: async () => {
      return await breakOptionsService.getBreakOptions();
    },
  });
};
